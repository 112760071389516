import { React, Suspense, useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useRoutes,
    HashRouter
} from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import Preloader from "../components/preloader";
import Gotop from "../components/gotop";

import AppEmployerRoutePath from "./subEmployer/AppEmployerRoutePath";
import Footer from "./subEmployer/Footer";
import Header from "./subEmployer/Header";


import Spinner from "../Employer/views/Spinner/Spinner";
import { CustomProvider } from 'rsuite';
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from "../Employer/assets/global/Theme-variable";//" './assets/global/Theme-variable';
const AppEmployer = () => {
    const [loading, setLoading] = useState(true);
    const theme = baseTheme;

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);

    }, []);

    return (
        <>
            <Suspense fallback={<Spinner />}>
                <CustomProvider>
                    <HashRouter>
                        {/* <Header /> */}
                        <ThemeProvider theme={theme}>
                            <AppEmployerRoutePath />
                        </ThemeProvider>
                    </HashRouter>
                </CustomProvider>
            </Suspense>
        </>
    );
};

export default AppEmployer;
