import React, { useState } from 'react';
    
import { Form, Button, ButtonToolbar, Schema, Panel, FlexboxGrid } from 'rsuite';
import {JSONTree} from 'react-json-tree';

const JSONView = ({ formValue, formError }) => (
    <div style={{ marginBottom: 10 }}>
        <Panel className="json-tree-wrapper" header={<p>formValue</p>}>
            <JSONTree data={formValue} />
        </Panel>

        <Panel className="json-tree-wrapper" header={<p>formError</p>}>
            <JSONTree data={formError} />
        </Panel>
    </div>
);

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
    name: StringType().isRequired(),
    email: StringType().isEmail().isRequired(),
    age: NumberType().range(18, 30),
    password: StringType().isRequired().proxy(['confirmPassword']),
    confirmPassword: StringType().equalTo('password')
});

const TextField = React.forwardRef((props, ref) => {
    const { name, label, accepter, ...rest } = props;
    return (
        <Form.Group ref={ref}>
            <Form.ControlLabel>{label} </Form.ControlLabel>
            <Form.Control name={name} accepter={accepter} {...rest} />
        </Form.Group>
    );
});



export default function Get() {
    const formRef = React.useRef();
    const [formError, setFormError] = React.useState({});
    const [formValue, setFormValue] = React.useState({
        name: '',
        email: '',
        age: '',
        password: '',
        confirmPassword: ''
    });

    const handleSubmit = () => {
        if (!formRef.current.check()) {
            console.error('Form Error');
            return;
        }
        console.log(formValue, 'Form Value');
    };

    const handleCheckEmail = () => {
        formRef.current.checkForField('email', checkResult => {
            console.log(checkResult);
        });
    };

    return (
        <FlexboxGrid>
            <FlexboxGrid.Item colspan={12}>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={model}
                >
                    <TextField name="name" label="Username" />
                    <TextField name="email" label="Email" />
                    <TextField name="age" label="Age" />
                    <TextField name="password" label="Password" type="password" autoComplete="off" />
                    <TextField
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        autoComplete="off"
                    />

                    <ButtonToolbar>
                        <Button appearance="primary" onClick={handleSubmit}>
                            Submit
                        </Button>

                        <Button onClick={handleCheckEmail}>Check Email</Button>
                    </ButtonToolbar>
                </Form>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={12}>
                <JSONView formValue={formValue} formError={formError} />
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};
