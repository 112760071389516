import { React, useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import Preloader from "../components/preloader";
import Gotop from "../components/gotop";

// layout
import Footer from "./subCandidate/Footer"; //footer
import AppRoutePath from "./subCandidate/AppRoutePath";//router
import Header from "./subCandidate/Header";//header


const AppCandidate = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <>
            {!loading ? (
                <BrowserRouter>
                    <Header />
                    <ScrollToTop />
                    <AppRoutePath />
                    <Footer />
                    <Gotop />
                </BrowserRouter>
            ) : (
                <Preloader />
            )}
        </>
    );
};

export default AppCandidate;
