import { CheckPicker, VStack, Form as RSForm, Schema as RSSchema, Modal as RSModal, Drawer as RSDrawer, ButtonToolbar as RSButtonToolbar, Button as RSButton, Input as RSInput, CheckboxGroup as RSCheckboxGroup, Radio as RSRadio, Checkbox as RSCheckbox, Toggle as RSToggle, Text as RSText, RadioGroup as RSRadioGroup, SelectPicker as RSSelectPicker } from 'rsuite';
import React, { useState, useEffect, useRef, useMemo } from "react";
import {
    Box,
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Button,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    Select,
    MenuItem,
    InputLabel,
    Typography,
    Autocomplete,
    Chip,
    Grid
} from "@mui/material";
import Api from "../../../../api/apiService";
import Constent from "../../../../api/constent";
import axios from "../../../../api/axios";
function AddQuestionsForm() {


    // Quat.
    const [openWithHeader, setOpenWithHeader] = useState(false);
    const [questions, setQuestions] = useState([]);
    const questionTypes = [
        { label: "Single Choice", value: "single" },
        { label: "Multiple Choice", value: "multiple" },
        { label: "Short Answer", value: "short" }
    ];

    // Getting list Question
    useEffect(() => {
        getQuestionMountList();

    }, []);

    const getQuestionMountList = async () => {
        const value = await Api.getQuestionsList();
        console.log(value);
        setQuestions(value);
    }
    const addQuestion = async () => {
        try {
            const newQuestion = {
                content: "Sample Question", // Ensure this is not empty
                type: "single",
                options: [],
                selectedOption: "",
                selectedOptions: [],
                preferredAnswer: "",
            };

            const forContent = {
                content: newQuestion.content,
                type: newQuestion.type,
                options: newQuestion.options,
                selected_option: newQuestion.selectedOption,
                preferred_answer: newQuestion.preferredAnswer,
            };

            // Validate content before sending
            if (!forContent.content || forContent.content.trim() === "") {
                console.error("Content is required!");
                return;
            }

            const response = await Api.getAddQuestions(forContent);
            console.log(`Question added successfully: ${JSON.stringify(response)}`);

            // Add to state if successful
            setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
        } catch (error) {
            console.error("Error adding question:", error.response?.data || error.message);
        }
    };

    // const addQuestion = () => {
    //     const newQuestion = {
    //         id: Date.now(),
    //         content: "",
    //         type: "single",
    //         options: [],
    //         selectedOption: "",
    //         selectedOptions: [],
    //         preferredAnswer: ""
    //     };
    //     setQuestions([...questions, newQuestion]);
    // };


    const updateQuestionContent = (id, value) => {
        setQuestions(questions.map((q) => (q.id === id ? { ...q, content: value } : q)));
        // updateApi(id);

    };

    const updateQuestionType = (id, value) => {
        setQuestions(questions.map((q) => (q.id === id ? { ...q, type: value } : q)));
        // updateApi(id);
    };



    const addOption = (id, value) => {
        setQuestions(
            questions.map((q) =>
                q.id === id
                    ? { ...q, options: [...q.options, value] }
                    : q
            )
        );
    };

    const removeOption = (id, option) => {
        setQuestions(
            questions.map((q) =>
                q.id === id
                    ? { ...q, options: q.options.filter((opt) => opt !== option) }
                    : q
            )
        );
    };

    const handleRadioChange = (id, value) => {
        setQuestions(
            questions.map((q) =>
                q.id === id ? { ...q, selectedOption: value } : q
            )
        );
    };

    const handleCheckboxChange = (id, values) => {
        setQuestions(
            questions.map((q) =>
                q.id === id ? { ...q, selectedOptions: values } : q
            )
        );
    };

    const handlePreferredAnswerChange = (id, selectedOption) => {
        setQuestions(
            questions.map((q) =>
                q.id === id
                    ? { ...q, preferredAnswer: selectedOption }
                    : q
            )
        );
    };

    const swapQuestions = (index1, index2) => {
        const newQuestions = [...questions];
        const temp = newQuestions[index1];
        newQuestions[index1] = newQuestions[index2];
        newQuestions[index2] = temp;
        setQuestions(newQuestions);
    };
    const getButtonStyle = (isActive) => {
        return isActive
            ? { backgroundColor: "#007bff", color: "#fff", borderColor: "#007bff" }  // Active state color
            : { backgroundColor: "#f8f9fa", color: "#333", borderColor: "#ccc" }; // Default state color
    };


    const duplicateQuestion = (id) => {
        const questionToDuplicate = questions.find((q) => q.id === id);
        if (questionToDuplicate) {
            const newQuestion = { ...questionToDuplicate, id: Date.now() };
            setQuestions([...questions, newQuestion]);
        }
    };

    const removeQuestion = (id) => {
        setQuestions(questions.filter((q) => q.id !== id));
        deleteQuestion(id);
    };


    const updateApi = async (id) => {

        const updatedQuestion = questions.find((q) => q.id === id);

        if (!updatedQuestion) {
            console.error("Question not found for ID:", id);
            return;
        }
        console.log(updatedQuestion);


        try {
            // Send the updated question to the backend
            const response = await axios.put(`${Constent.Employer_QuestionsUpdate}${id}`, {
                content: updatedQuestion.content,
                type: updatedQuestion.type,
                options: updatedQuestion.options,
                selected_option: updatedQuestion.selectedOption,
                preferred_answer: updatedQuestion.preferredAnswer, // Ensure options are passed if required
            });

            console.log("Update successful:", response.data);
        } catch (error) {
            console.error("Error updating question:", error.response?.data || error.message);
        }
    };
    const deleteQuestion = async (id) => {
        try {
            const response = await axios.delete(`${Constent.Employer_QuestionsDelete}${id}`);
            console.log(response.data.message);

            // Optional: Update your UI or state after deletion
            setQuestions(questions.filter((q) => q.id !== id));
        } catch (error) {
            console.error('Error deleting question:', error.response?.data?.message || error.message);
        }
    };

    return (
        <Box sx={{ border: "1px solid #ccc", borderRadius: "5px", padding: "10px" }} >
            <Typography
                variant="subtitle1"
                sx={{
                    fontWeight: "bold",
                }}
                gutterBottom
            >
                Questions for candidates </Typography>
            <Typography variant="body2" gutterBottom>
                To better evaluate candidates, ask them questions regarding the job requirement
            </Typography>
            <div>
                <RSButtonToolbar>
                    <RSButton onClick={() => setOpenWithHeader(true)}>Add a Question</RSButton>
                </RSButtonToolbar>


                <RSDrawer
                    size="lg"
                    placement="right"
                    open={openWithHeader}
                    onClose={() => setOpenWithHeader(false)}
                    style={{ padding: '0px', margin: '10px' }}
                >
                    <RSDrawer.Header>
                        <RSDrawer.Title>Question Manager</RSDrawer.Title>
                    </RSDrawer.Header>
                    <RSDrawer.Body>
                        <RSButton onClick={addQuestion} appearance="primary" style={{ marginBottom: "15px" }}>
                            Add Question
                        </RSButton>
                        {questions.map((question, index) => (
                            <div
                                key={question.id}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: "15px",
                                    padding: "10px",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px"
                                }}

                            >
                                {/* Question Content */}
                                <RSInput
                                    value={question.content}
                                    onChange={(value) => updateQuestionContent(question.id, value)}
                                    placeholder="Enter question"
                                    style={{ marginBottom: "10px" }}
                                />

                                {/* Question Type Selector */}
                                <RSSelectPicker
                                    data={questionTypes}
                                    value={question.type}
                                    onChange={(value) => updateQuestionType(question.id, value)}
                                    style={{ width: "200px", marginBottom: "10px" }}
                                />

                                {/* Options for Single Choice */}
                                {question.type === "single" && (
                                    <>
                                        <RSRadioGroup
                                            value={question.selectedOption}
                                            onChange={(value) => handleRadioChange(question.id, value)}
                                            style={{ marginTop: "10px" }}
                                        >
                                            {question.options.map((option, i) => (
                                                <div
                                                    key={i}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginBottom: "5px"
                                                    }}
                                                >
                                                    <RSRadio value={option} style={{ flex: 1 }}>
                                                        {option}
                                                    </RSRadio>
                                                    <RSButton size="xs" style={{ color: 'white', backgroundColor: 'gray' }} onClick={() => removeOption(question.id, option)}>
                                                        Remove
                                                    </RSButton>
                                                    <RSToggle
                                                        style={{
                                                            marginLeft: "10px",
                                                        }}
                                                        defaultChecked color="green"
                                                        checked={question.preferredAnswer === option}
                                                        onChange={() => handlePreferredAnswerChange(question.id, option)}
                                                    /> &nbsp;
                                                    <RSText muted>preferred answer</RSText>

                                                </div>
                                            ))}
                                        </RSRadioGroup>
                                        <RSInput
                                            placeholder="Add Option and Enter Here !"
                                            onPressEnter={(e) => {
                                                addOption(question.id, e.target.value);
                                                e.target.value = "";
                                            }}
                                        />
                                    </>
                                )}

                                {/* Options for Multiple Choice */}
                                {question.type === "multiple" && (
                                    <>
                                        <RSInput
                                            placeholder="Add option"
                                            onPressEnter={(e) => {
                                                addOption(question.id, e.target.value);
                                                e.target.value = "";
                                            }}
                                        />
                                        <RSCheckboxGroup
                                            value={question.selectedOptions}
                                            onChange={(values) => handleCheckboxChange(question.id, values)}
                                        >
                                            {question.options.map((option, i) => (
                                                <div key={i} style={{ display: "flex", alignItems: "center" }}>
                                                    <RSCheckbox value={option} style={{ flex: 1 }}>
                                                        {option}
                                                    </RSCheckbox>
                                                    <RSButton size="xs" onClick={() => removeOption(question.id, option)}>
                                                        Remove
                                                    </RSButton>
                                                    <RSToggle
                                                        defaultChecked color="green"
                                                        checked={question.preferredAnswer === option}
                                                        onChange={() => handlePreferredAnswerChange(question.id, option)}
                                                        style={{ marginLeft: "10px" }}
                                                    />
                                                </div>
                                            ))}
                                        </RSCheckboxGroup>
                                    </>
                                )}

                                {/* Short Answer Input */}
                                {question.type === "short" && (
                                    <RSInput placeholder="Short answer input" disabled />
                                )}

                                {/* Question Actions */}
                                <RSButtonToolbar style={{ marginTop: "10px" }}>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>

                                        <div style={{ display: 'flex', gap: '5px' }}>

                                            <RSButton
                                                size="xs"
                                                onClick={() => swapQuestions(index, index - 1)}
                                                disabled={index === 0}
                                                style={getButtonStyle(index !== 0)}
                                            >
                                                ↑
                                            </RSButton>

                                            <RSButton
                                                size="xs"
                                                onClick={() => swapQuestions(index, index + 1)}
                                                disabled={index === questions.length - 1}
                                                style={getButtonStyle(index !== questions.length - 1)}
                                            >
                                                ↓
                                            </RSButton>
                                            <RSButton
                                                size="xs"
                                                style={{ color: 'white', backgroundColor: 'orange' }}
                                                onClick={() => duplicateQuestion(question.id)}
                                            >
                                                Duplicate
                                            </RSButton>
                                            <RSButton
                                                size="xs"
                                                style={{ color: 'white', backgroundColor: 'red' }}
                                                onClick={() => removeQuestion(question.id)}
                                                appearance="subtle"
                                            >
                                                Remove
                                            </RSButton>
                                        </div>

                                        <RSButton
                                            size="xs"
                                            appearance="primary"
                                            // style={{ color: 'white', backgroundColor: 'yellow' }}
                                            onClick={() => updateApi(question.id)}
                                        >
                                            Update
                                        </RSButton>
                                    </div>



                                </RSButtonToolbar>
                            </div>
                        ))}

                    </RSDrawer.Body>
                </RSDrawer>
            </div>

        </Box>

    );
}

export default AddQuestionsForm;