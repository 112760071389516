import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import axios from '../../api/axios';
import { sweetAlert } from '../../utils/sweetAlert';

import apiService from "../../api/apiService";
import domain from "../../api/constent";
import Session from "../../api/session";
import { ShimmerDiv, ShimmerCategoryItems } from 'shimmer-effects-react';
import { Api } from '@mui/icons-material';
import AuthUser from '../../middleware/AuthMiddleware';

export default function CardComp() {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [getJson, setTempJson] = useState({});

    // Fetch personal details on component load
    useEffect(() => {
        PersonalDetails();
    }, []);

    const PersonalDetails = async () => {
        try {
            setLoading(true);
            const resp = await apiService.fetchPersonalDetails();
            if (resp) {
                setTempJson({
                    first_name: resp.first_name || 'John',
                    last_name: resp.last_name || 'Doe',
                    email: resp.email || 'john.doe@example.com',
                    home_location: resp.home_location || '123 Main St, Springfield',
                    phone_no: resp.phone_no || '123-456-7890',
                    country_code: resp.country_code || 'us',
                });
            }
        } catch (error) {
            console.error('Error fetching personal details:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTempJson((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handlePhoneChange = (value, country) => {
        setTempJson((prevState) => ({
            ...prevState,
            phone_no: value,
            country_code: country?.dialCode || '1',
        }));
    };

    const handleUpdate = async () => {
        setLoading(true);
        try {
            const resp = await apiService.updatePersonalDetails(getJson);
            if (resp) {
                handleClose();
                sweetAlert('Success!', 'Your personal details have been updated.', 1);
            }
        } catch (error) {
            sweetAlert('Error!', 'Failed to update personal details. Try again.', 2);
        } finally {
            setLoading(false);
        }
    };

    const valueJson = useMemo(
        () => ({
            name: `${getJson.first_name} ${getJson.last_name}`,
            gmail: getJson.email,
            phone: `+${getJson.country_code} ${getJson.phone_no}`,
            location: getJson.home_location,
        }),
        [getJson]
    );

    const commonStyles = {
        display: 'flex',
        position: 'relative',
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'column',
        color: 'white',
        padding: '20px',
        borderRadius: '20px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'radial-gradient(circle, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 107, 1) 100%)',
        maxWidth: '1300px',
        height: '100%', // Ensure consistent height
    };
    return (
        <div>
            {loading ? (
                <ShimmerCategoryItems
                    mode="light"
                    items={1}
                    gap={10}
                    sx={commonStyles}
                    style={{
                        ...commonStyles,
                        height: '100%', // Ensure the height matches
                    }}
                />
            ) : (
                <Box
                    sx={commonStyles}
                >
                    {/* <IconButton
                        onClick={handleOpen}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            borderRadius: '50%',
                            padding: '8px',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        }}
                    >
                        <EditIcon />
                    </IconButton> */}

                    <Typography variant="h5" component="div" gutterBottom>
                        {valueJson.name || 'Name not available'}
                    </Typography>
                    <Box display="flex" alignItems="center" mt={1}>
                        <LocationOnIcon sx={{ marginRight: 1 }} />
                        <Typography variant="body2">{valueJson.location || 'Location not available'}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mt={1}>
                        <CallIcon sx={{ marginRight: 1 }} />
                        <Typography variant="body2">{valueJson.phone || 'Phone not available'}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mt={1}>
                        <MailIcon sx={{ marginRight: 1 }} />
                        <Typography variant="body2">{valueJson.gmail || 'Email not available'}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mt={1}>
                        <Button onClick={handleOpen} size="small"
                            variant="contained"
                            color="primary">
                            Edit</Button>
                    </Box >

                </Box>
            )}

            {open && (
                <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                    <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                            <Typography variant="h6">Edit Personal Details for {valueJson.name}</Typography>
                            <Box display="flex" flexDirection="column" mt={2}>
                                <TextField
                                    label="First Name"
                                    variant="outlined"
                                    value={getJson.first_name}
                                    onChange={handleInputChange}
                                    name="first_name"
                                    size="small"
                                    fullWidth
                                />
                                <TextField
                                    label="Last Name"
                                    variant="outlined"
                                    value={getJson.last_name}
                                    onChange={handleInputChange}
                                    name="last_name"
                                    size="small"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                />
                            </Box>

                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    label="Address Location"
                                    multiline
                                    value={getJson.home_location}
                                    onChange={handleInputChange}
                                    name="home_location"
                                    maxRows={4}
                                    fullWidth
                                    size="small"
                                />
                            </Box>

                            <Box sx={{ mt: 2 }}>
                                <PhoneInput
                                    country={getJson.country_code || 'us'}
                                    value={getJson.phone_no}
                                    onChange={handlePhoneChange}
                                    inputStyle={{
                                        width: '100%',
                                        height: '40px',
                                        fontSize: '16px',
                                    }}
                                    containerStyle={{
                                        width: '100%',
                                    }}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleUpdate} color="primary" disabled={loading}>
                            {loading ? 'Saving...' : 'Save Changes'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
