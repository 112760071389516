import React, { useState, useEffect, useRef, useMemo } from "react";
import {
    Box,
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Button,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    Select,
    MenuItem,
    InputLabel,
    Typography,
    Autocomplete,
    Chip,
    Grid
} from "@mui/material";
// import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";
import Session from '../../api/session';
import axios from '../../api/axios';
import domain from "../../api/constent";
import { ShimmerCategoryItems } from "shimmer-effects-react";
import apiService from "../../api/apiService";
import CloseIcon from "@rsuite/icons/Close";

// import '../assets/styles/styles.css';
import KeySkills from "./PostVacancy/skills/SkillJobPost";
import SearchSelect from "./PostVacancy/search-select/SearchSelect";
import WorkModeDropdown from "./PostVacancy/work-mode/WorkModeDropdown";
import SalaryForm from "./PostVacancy/salary-form/SalaryForm";
import IndustrySelection from './PostVacancy/Candidate-Industry/IndustrySelection';
import { sweetAlert } from '../../utils/sweetAlert';
import Get from '../../Employer/components/PostVacancy/Test/Get';
import JODITEditor from "./Forms/SummarNote/JoditEditor";
import DiversityHiring from "./PostVacancy/Diversity-Hiring/DiversityHiring";
import CollaborationSettings from "./PostVacancy/Collaboration-Settings/CollaborationSettings";
import CompanyInformation from "./PostVacancy/Company-Info/CompanyInformation";
import CompNestedSelect from "../components/Forms/Inputs/CompNestedSelect";
import SearchableGroupedSelect from "../components/Forms/Inputs/SearchableGroupedSelect";
import CompCheckBox from "./Forms/Inputs/CompCheckBox";
import CompNiceModal from "../components/Forms/Inputs/CompNiceModel";
import NiceModal from '@ebay/nice-modal-react';
import { CheckPicker, TreePicker, InputPicker as RSInputPicker, InlineEdit as RSInlineEdit, VStack, Schema, Panel, FlexboxGrid, Form, Uploader, CheckPicker as RSCheckPicker, Drawer as RSDrawer, ButtonToolbar as RSButtonToolbar, Radio as RSRadio, Button as RSButton, Input as RSInput, CheckboxGroup as RSCheckboxGroup, Checkbox as RSCheckbox, Toggle as RSToggle, Text as RSText, RadioGroup as RSRadioGroup, SelectPicker as RSSelectPicker, CheckTreePicker, Toggle, IconButton, Stack, Whisper, Tooltip } from 'rsuite';
import { JSONTree } from 'react-json-tree';
import AddQuestionsForm from "./PostVacancy/Add-Questions/AddQuestionsForm";
import jobAppliedModel from "../../Employer/model/JobPostingApply";
import CompAutoSearch from "./Forms/rsuite/CompAutoSearch";
import CompSelectSearch from "../../Employer/components/Forms/rsuite/CompSelectSearch";
import CompRSTextField from "../../Employer/components/Forms/rsuite/CompRSTextField";
import PostJobPreview from "../../Employer/views/post-vacancy/PostJobPreview.jsx";

import { Link, useNavigate } from "react-router-dom";
import { mockTreeData } from "../../api/mock/mock";
import CustomTagPicker from "./Forms/rsuite/JobPost/CustomTagPicker.jsx";
import ImageUploadPreviewRSuite from "../../Employer/components/PostVacancy/Test/ImageUploadPreviewRSuite";

const model = Schema.Model({
    jobTitle: Schema.Types.StringType().isRequired('Job title is required'),
    jobType: Schema.Types.ArrayType().minLength(1, 'Select at least one Employee Type').isRequired('Please select at least one Employee Type'),
    keySkills: Schema.Types.ArrayType().minLength(2, 'Select at least two skills').isRequired('Please select at least two skills'),
    departmentCategory: Schema.Types.StringType().isRequired('Please select Department Category'),
    role: Schema.Types.StringType().isRequired('Please select Role'),
    workMode: Schema.Types.NumberType().isRequired('Please select at least one Work Mode'),
    // jobLocation: Schema.Types.ArrayType().minLength(3, 'Select at least one Job Location').isRequired('Please select at least one Job Location'),
    // // hybridPolicy: Schema.Types.StringType(),
    // // baseLocationRemote: Schema.Types.StringType().isRequired('Please select Base Location Remote Job'),
    minYear: Schema.Types.StringType().isRequired('Please select a Min Year'),
    maxYear: Schema.Types.StringType().isRequired('Please select a Max Year'),
    salaryType: Schema.Types.StringType().isRequired('Please select a Salary Type'),
    salaryProType: Schema.Types.StringType().isRequired('Please select a Salary Process Type'),
    minSalary: Schema.Types.StringType().isRequired('Please select a Min Salary'),
    maxSalary: Schema.Types.StringType().isRequired('Please select a Max Salary'),
    checkPercentage: Schema.Types.BooleanType(),
    // // percentage: Schema.Types.NumberType()
    // //     .addRule((value, data) => {
    // //         if (data.checkPercentage == true) {
    // //             return true;
    // //         }
    // //         return false;
    // //     }).isRequired('Please Enter Percentage %'),
    companyIndustries: Schema.Types.StringType().isRequired('Please select a Company Industries'),
    educations: Schema.Types.ArrayType().isRequired('Please select a Education & Qualification'),
    candidateIndustries: Schema.Types.ArrayType().minLength(2, 'Select at least two Industries').isRequired('Please select at least two Industries'),
    isDiversityHiringChecked: Schema.Types.BooleanType(),
    jobDescription: Schema.Types.StringType().isRequired('Please Enter Job Description'),
    // vacancy: Schema.Types.StringType().isRequired('Please select an vacancy'),
    // additionalVacancy: Schema.Types.StringType()
    //     .addRule((value, data) =>
    //         data.vacancy === "true"
    //             ? value && value.trim().length > 0
    //             : true, // Skip validation if `vacancy` is "false"
    //         'Please enter additional vacancy when vacancy is "true".'
    //     ),
    requestCandidateVideoProfile: Schema.Types.BooleanType(),
    requestIncludeWalkInDetails: Schema.Types.BooleanType(),
    requestIncludeWalkInText: Schema.Types.StringType()
        .addRule((value, data) =>
            data.requestIncludeWalkInDetails === true
                ? value && value.trim().length > 0
                : true,
            'Please enter Walk In Text.'
        ),
    companyName: Schema.Types.StringType().isRequired('Please Enter Company Name'),
    aboutCompany: Schema.Types.StringType().isRequired('Please Enter About Company Details'),
    companySize: Schema.Types.StringType().isRequired('Please Enter Company Size'),
    isSendMailChecked: Schema.Types.BooleanType(),
    selectedUserEmails: Schema.Types.ArrayType(),
    notificationFrequencyLists: Schema.Types.StringType(),
    companyEmail: Schema.Types.StringType().isRequired('Please Enter Company Email'),
    companyEmployeeSize: Schema.Types.StringType(),
    headquarters: Schema.Types.StringType().isRequired('Please Enter Headquarters'),
    foundedYear: Schema.Types.StringType().isRequired('Please Enter Founded Year'),
    socialLinkFB: Schema.Types.StringType(),
    socialLinkIn: Schema.Types.StringType(),
    socialLinkTw: Schema.Types.StringType(),
    socialLinkIns: Schema.Types.StringType(),
    socialLinkYou: Schema.Types.StringType()

});

const RSTextField = React.forwardRef((props, ref) => {
    const { name, label, required, accepter, error, ...rest } = props;
    return (
        <Form.Group ref={ref}>
            <Form.ControlLabel>
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: "bold",
                        fontSize: "0.875rem",
                    }}
                >
                    {label} {required === true ? <span style={{ color: 'red' }}>*</span> : null}
                    {/* Work Experience(Years) <span style={{ color: "red" }}>*</span> */}
                </Typography>

            </Form.ControlLabel>
            <Form.Control name={name} accepter={accepter} {...rest} />
            {error && (
                <div style={{ color: 'red', marginTop: '4px', fontSize: '12px' }}>
                    {error}
                </div>
            )}
        </Form.Group>
    );
});
const JSONView = ({ formValue, formError }) => (
    <div style={{ marginBottom: 10 }}>
        <Panel className="json-tree-wrapper" header={<p>formValue</p>}>
            <JSONTree data={formValue} />
        </Panel>

        <Panel className="json-tree-wrapper" header={<p>formError</p>}>
            <JSONTree data={formError} />
        </Panel>
    </div>
);

const PostJobFormVacancy = () => {



    const navigate = useNavigate();
    // Job Description
    const placeholderJobDescription = `<div>
                  <h6>Role & responsibilities</h6>
                    <small>Outline the day-to-day responsibilities for this role.</small>
                    <br/><br/>
                    <h6>Preferred candidate profile</h6>
                    <small>Specify required role experience and skills, previous job experience, or relevant certifications.</small>
                    <br/><br/>
                    <h6>Perks and Benefits:</h6>
                    <small>Mention available facilities and benefits the company is offering with the job.</small>
                </div>`;
    const noteRefer = useRef(null);

    // about Company Details
    const placeholderCompInfo = `<div><b>Company Information :<b/>* <br />
    <small >Are you a User Experience Designer with a track record of delivering intuitive digital experiences that drive results? Are you a strategic storyteller and systems thinker who can concept and craft smart, world-class campaigns across a variety of mediums?
    Deloitte's Green Dot Agency is looking to add a Lead User Experience Designer to our experience design team. We want a passionate creative who's inspired by new trends and emerging technologies, and is able to integrate them into memorable user experiences. A problem solver who is entrepreneurial, collaborative, hungry, and humble; can deliver beautifully designed, leading-edge experiences under tight deadlines; and who has demonstrated proven expertise.</small> </div> `;
    const noteReferAboutCompany = useRef(null);

    const storedFormValue = JSON.parse(sessionStorage.getItem('formValue') || '{}');//previous stores

    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({
        jobTitle: storedFormValue.jobTitle || '',
        jobType: storedFormValue.jobType || [],//'1', '2'
        keySkills: storedFormValue.keySkills || [],//'React', 'Python'
        departmentCategory: storedFormValue.departmentCategory || '',
        role: storedFormValue.role || '',
        workMode: storedFormValue.workMode || '',//new
        jobLocationList: storedFormValue.jobLocationList || [],//new
        baseLocationRemote: storedFormValue.baseLocationRemote || '',
        hybridPolicy: storedFormValue.hybridPolicy || '',
        minYear: storedFormValue.minYear || '',
        maxYear: storedFormValue.maxYear || '',
        salaryType: storedFormValue.salaryType || '',
        salaryProType: storedFormValue.salaryProType || '',
        minSalary: storedFormValue.minSalary || '',
        maxSalary: storedFormValue.maxSalary || '',
        checkPercentage: storedFormValue.checkPercentage || '',//false
        percentage: storedFormValue.percentage || 0,
        variableSalaryType: storedFormValue.variableSalaryType || '₹',
        variableMinSalary: storedFormValue.variableMinSalary || 0,
        variableMaxSalary: storedFormValue.variableMaxSalary || 0,
        companyIndustries: storedFormValue.companyIndustries || '',
        hideSalary: storedFormValue.hideSalary || false,
        educations: storedFormValue.educations || [],
        candidateIndustries: storedFormValue.candidateIndustries || [],
        isDiversityHiringChecked: storedFormValue.isDiversityHiringChecked || false,
        jobDescription: storedFormValue.jobDescription || '',
        vacancy: storedFormValue.vacancy || false, //issue if yes then
        additionalVacancy: storedFormValue.additionalVacancy || '', //issue
        requestCandidateVideoProfile: storedFormValue.requestCandidateVideoProfile || true,
        requestIncludeWalkInDetails: storedFormValue.requestIncludeWalkInDetails || true,
        requestIncludeWalkInText: storedFormValue.requestIncludeWalkInText || '',
        companyName: storedFormValue.companyName || '',
        companyWebsite: storedFormValue.companyWebsite || '',
        aboutCompany: storedFormValue.aboutCompany || '',
        isSendMailChecked: storedFormValue.isSendMailChecked || false,
        selectedUserEmails: storedFormValue.selectedUserEmails || [],
        notificationValue: storedFormValue.notificationValue || [],
        companyEmail: storedFormValue.companyEmail || '',
        companyVideoContent: storedFormValue.companyVideoContent || '',//new
        companyImageUpload: storedFormValue.companyImageUpload || [],
        companySize: storedFormValue.companySize || [],
        headquarters: storedFormValue.headquarters || '',
        foundedYear: storedFormValue.foundedYear || '',
        socialLinkFB: storedFormValue.socialLinkFB || '',
        socialLinkIn: storedFormValue.socialLinkIn || '',
        socialLinkTw: storedFormValue.socialLinkTw || '',
        socialLinkIns: storedFormValue.socialLinkIns || '',
        socialLinkYou: storedFormValue.socialLinkYou || '',

    });
    const handleHideSalaryChange = () => {
        setFormValue((prevState) => ({
            ...prevState,
            hideSalary: !prevState.hideSalary,
        }));
    };
    const regex = /^\d+(\.\d{0,1})?$/;
    const datase = mockTreeData({
        limits: [3, 3, 4],
        labels: (layer, value, faker) => {
            const methodName = ['jobArea', 'jobType', 'firstName'];
            return faker.person[methodName[layer]]();
        }
    });

    const handleVacancyChanges = (val) => {
        setFormValue((prev) => ({
            ...prev,
            vacancy: val,
            additionalVacancy: val === "false" ? "" : prev.additionalVacancy, // Clear additionalVacancy if "No"
        }));
    };


    const [employeeTypes, setEmploymentList] = useState([]);
    const [skillsList, setSkillsList] = useState([]);
    const [departmentCategoryList, setDepartmentList] = useState([]);
    const [roleCategoryList, setRoleList] = useState([]);
    const [workModeList, setWorkModes] = useState([]);
    const [jobLocationList, setJobLocations] = useState([]);
    const [workExpList, setWorkExpList] = useState([]);
    const [salaryTypeList, setSalaryTypeList] = useState([]);
    const [salaryProcessTypeList, setSalaryProcessTypeList] = useState([]);
    const [salaryRangeList, setSalaryRangeList] = useState([]);
    const [companyIndustriesList, setCompanyIndustriesList] = useState([]);
    const [educationOptionsList, setEducationOptionsList] = useState([]);
    const [candidateIndustriesList, setCandidateIndustriesList] = useState([]);
    const [notificationFrequencyLists, setNotificationFrequencyLists] = useState([]);
    const [companySizeList, setCompanySizeList] = useState([]);
    const [companySubUserEmails, setCompanySubUserEmails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();

        GetRoleListMount(formValue?.departmentCategory);
    }, []);
    const onChangeDepartment = (e) => {
        console.log(e);
        GetRoleListMount(e);
    }
    const fetchData = async () => {
        try {
            setLoading(true);

            // Make all API calls concurrently
            const [employmentData, skillsData, departmentData, workModeData, jobLocationData, workExpData, salaryTypeData, salaryProcessTypeData, salaryRangeData, companyIndustriesData, educationOptionsData, candidateIndustriesData, notificationFrequencyData, companySizeData, companySubUserData] = await Promise.all([
                apiService.GetEmploymentType(),
                apiService.GetKeySkillsList(),
                apiService.GetDepartmentList(),
                apiService.GetWorkModeList(),
                apiService.GetJobLocationList(),
                apiService.GetWorkExpList(),
                apiService.GetSalaryTypeList(),
                apiService.GetSalaryProcessTypeList(),
                apiService.GetSalaryRangeList(),
                apiService.GetCompanyIndustryList(),
                apiService.GetEducationQualificationList(),
                apiService.GetCandidateIndustryList(),
                apiService.GetNotificationList(),
                apiService.GetCompanySizeList(),
                apiService.GetEmailSubUserList(),
            ]);

            // Update state with fetched data
            setEmploymentList(employmentData);
            setSkillsList(skillsData);
            setDepartmentList(departmentData);
            setWorkModes(workModeData);
            setJobLocations(jobLocationData);
            setWorkExpList(workExpData);
            setSalaryTypeList(salaryTypeData);
            setSalaryRangeList(salaryRangeData);
            setSalaryProcessTypeList(salaryProcessTypeData);
            setCompanyIndustriesList(companyIndustriesData);
            setEducationOptionsList(educationOptionsData);
            setCandidateIndustriesList(candidateIndustriesData);
            setNotificationFrequencyLists(notificationFrequencyData);
            setCompanySizeList(companySizeData);
            setCompanySubUserEmails(companySubUserData);

        } catch (err) {
            console.error("Error fetching data:", err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };


    const GetRoleListMount = async (departmentCategoryList) => {
        const value = await apiService.GetRoleList(departmentCategoryList);
        setRoleList(value);
    }


    const handleChange = (values) => {
        const selectedOptions = employeeTypes.filter(option => values.includes(option.value));
        setFormValue({ ...formValue, jobType: selectedOptions });
        console.log(selectedOptions);
    };

    const salaryTypeChange = (typeSelect) => {
        setFormValue((prevState) => ({
            ...prevState,
            salaryType: typeSelect,
        }));
        handlePercentageChanges(formValue.percentage, formValue.salaryType, formValue.minSalary, formValue.maxSalary);
    };
    const minSalaryChange = (selectedValue) => {

        setFormValue((prevState) => ({
            ...prevState,
            minSalary: selectedValue,
        }));

        handlePercentageChanges(formValue.percentage, formValue.salaryType, selectedValue, formValue.maxSalary);
    };

    const maxSalaryChange = (selectedValue) => {
        setFormValue((prevState) => ({
            ...prevState,
            maxSalary: selectedValue,
        }));

        handlePercentageChanges(formValue.percentage, formValue.salaryType, formValue.minSalary, selectedValue);
    };
    const salaryProcessTypeChange = (selectedValue) => {
        setFormValue((prevState) => ({
            ...prevState,
            salaryProType: selectedValue,
        }));
    };

    const handlePercentageChanges = (percentageValue, salaryType = formValue.salaryType, minSalary = formValue.minSalary, maxSalary = formValue.maxSalary) => {
        if (!percentageValue || isNaN(percentageValue) || percentageValue < 0) {
            console.log('Invalid percentage value:', percentageValue);
            return;
        }

        console.log('Percentage Value:', percentageValue);
        console.log('Min Salary:', minSalary);
        console.log('Max Salary:', maxSalary);

        const selectedMinOptions = salaryRangeList.find(option => String(minSalary) === String(option.value))?.label || '0';
        const selectedMaxOptions = salaryRangeList.find(option => String(maxSalary) === String(option.value))?.label || '0';

        const selectedTypeOptions = salaryTypeList.find(option => String(salaryType) === String(option.value))?.label || '₹';

        const numericSelectedMin = parseFloat(selectedMinOptions.replace(',', '')) || 0;
        const numericSelectedMax = parseFloat(selectedMaxOptions.replace(',', '')) || 0;

        const vMin = ((numericSelectedMin * percentageValue) / 100).toFixed(2);
        const vMax = ((numericSelectedMax * percentageValue) / 100).toFixed(2);

        setFormValue((prevState) => ({
            ...prevState,
            percentage: percentageValue,
            variableSalaryType: selectedTypeOptions,
            variableMinSalary: vMin,
            variableMaxSalary: vMax,
        }));

        console.log('Calculated Labels:', selectedTypeOptions);
        console.log('Calculated vMin:', vMin);
        console.log('Calculated vMax:', vMax);
    };

    const jobTypeRef = useRef();


    const handleSubmit = () => {
        // formRef.current.checkForField('email', checkResult => {
        //     console.log(checkResult);
        // });

        if (!formRef.current.check()) {
            console.error('Form Error');
            sweetAlert('Error! Please Check values and try again', formRef.current.check(), 2); // Error
            return;
        }
        const formData = formValue;
        sweetAlert('Success ', 'Preview Page Next', 1); // Error

        navigate("/post-review", { state: { formData, employeeTypes, skillsList, departmentCategoryList, roleCategoryList, workModeList, jobLocationList, workExpList, salaryTypeList, salaryProcessTypeList, salaryRangeList, companyIndustriesList, educationOptionsList, candidateIndustriesList, companyIndustriesList, companySizeList, notificationFrequencyLists, companySubUserEmails } });   // navigate("/post-review");

        console.log(formValue, 'Form Value');
    };
    const handleSendStatus = (formData) => {
        console.log(formData);
        navigate("/post-review", { state: { formData, employeeTypes, skillsList, departmentCategoryList, roleCategoryList, workModeList, jobLocationList, workExpList, salaryTypeList, salaryProcessTypeList, salaryRangeList, companyIndustriesList, educationOptionsList, candidateIndustriesList, companyIndustriesList, companySizeList, notificationFrequencyLists, companySubUserEmails } });   // navigate("/post-review");
    };

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files); // Convert FileList to an array

        const newImages = files.map((file) => ({
            id: file.name, // Use a unique identifier (e.g., file name or generate a UUID)
            src: URL.createObjectURL(file), // Generate a preview URL
            file, // Store the file for upload
        }));

        setFormValue((prevState) => ({
            ...prevState,
            companyImageUpload: [...(prevState.companyImageUpload || []), ...newImages], // Append new images
        }));
    };

    const removeImage = (id) => {
        setFormValue((prevState) => ({
            ...prevState,
            companyImageUpload: prevState.companyImageUpload.filter((image) => image.id !== id),
        }));
    };
    return (

        <Box
            component="form"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                maxWidth: 600,
                margin: "auto",
            }}
        >
            <Typography
                variant="subtitle1"
                sx={{
                    fontWeight: "bold",
                }}
            >Post a Job Vacancy
            </Typography>
            <Grid xs={12}
                lg={12}
                sm={12}
                md={12}
            >
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    formValue={formValue}
                    model={model}
                >
                    <RSTextField name="jobTitle" label="Job Title" required={true} />
                    <RSTextField
                        ref={jobTypeRef}
                        name="jobType"
                        label="Employment Type"
                        required={true}
                        accepter={({ value, onChange }) => (
                            <CustomTagPicker
                                value={value}
                                apiList={employeeTypes}
                                onHandlerChange={onChange}
                                placeholder="Select Employee Type"
                            />
                        )}
                    />
                    <RSTextField
                        name="keySkills"
                        label="Key Skills"
                        required={true}
                        accepter={({ value, onChange }) => (
                            <CompAutoSearch value={value} onChange={onChange} list={skillsList} />
                        )}
                    />
                    <RSTextField
                        name="departmentCategory"
                        required={true}
                        label="Department Category"
                        onChange={onChangeDepartment}
                        accepter={({ value, onChange }) => (
                            <CompSelectSearch value={value} onChange={onChange} list={departmentCategoryList} />
                        )}
                    />
                    {/* <RSTextField
                        name="roleCategory"
                        required={true}
                        label="Role Category"
                        accepter={({ value, onChange }) => (
                            <CompSelectSearch value={value} onChange={onChange} list={roleCategoryList} />
                        )}
                    /> */}
                    <RSTextField
                        name="role"
                        required={true}
                        label="Role"
                        accepter={({ value, onChange }) => (
                            <CompSelectSearch value={value} onChange={onChange} list={roleCategoryList} />
                        )}
                    />
                    <RSTextField
                        name="workMode"
                        required={true}
                        label="Work Mode"
                        accepter={({ value, onChange }) => (
                            <CompSelectSearch value={value} onChange={onChange} list={workModeList} />
                        )}
                    />
                    {formValue.workMode === 1 || formValue.workMode === 2 ? (
                        <RSTextField
                            name="jobLocation"
                            required={true}
                            label="Job Location"
                            accepter={({ value, onChange }) => (
                                <CompSelectSearch value={value} onChange={onChange} list={jobLocationList} />
                            )}
                        />

                    ) : (<></>)}
                    {(formValue.workMode === 2) ? (
                        <RSTextField
                            name="hybridPolicy"
                            required={true}
                            label="Define your hybrid policy"
                            accepter={({ value, onChange }) => (
                                <RSInlineEdit placeholder="Click to Define your hybrid policy" style={{ width: 300 }}>
                                    <RSInput as="textarea" rows={5} />
                                </RSInlineEdit>)}
                        />) : (formValue.workMode === 3) ? (
                            <RSTextField
                                name="baseLocationRemote"
                                required={true}
                                label="Base location for remote job"
                                accepter={({ value, onChange }) => (
                                    <CompSelectSearch value={value} onChange={onChange} list={jobLocationList} />
                                )}
                            />
                        ) : (<></>)}
                    <FormControl fullWidth>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: "bold",
                            }}
                        >
                            Work Experience(Years) <span style={{ color: "red" }}>*</span>
                        </Typography>
                        {/* Work Experience */}
                        <Box display="flex" alignItems="center" sx={{ grap: 2 }}>
                            <Typography variant="body1" sx={{ m: 1 }}></Typography>

                            <RSTextField
                                name="minYear"
                                required={true}
                                label="Min Years"
                                accepter={({ value, onChange }) => (
                                    <CompSelectSearch value={value} onChange={onChange} list={workExpList} />
                                )}
                            />

                            <Typography variant="body1" sx={{ mx: 1 }}>to</Typography>
                            <RSTextField
                                name="maxYear"
                                required={true}
                                label="Max Years"
                                accepter={({ value, onChange }) => (
                                    <CompSelectSearch value={value} onChange={onChange} list={workExpList} />
                                )}
                            />
                        </Box>
                    </FormControl>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontWeight: "bold",
                        }}
                        gutterBottom
                    >
                        Annual Salary Range  <span style={{ color: "red" }}>*</span>
                    </Typography>

                    <Typography variant="body2">Enter the salary offered for this job</Typography>
                    <Box display="flex" alignItems="center" sx={{ grap: 3 }}>

                        <Typography variant="body1" sx={{ mx: 1 }}></Typography>

                        <RSTextField
                            name="salaryType"
                            required={true}
                            label="Salary Type"
                            onChange={salaryTypeChange}
                            accepter={({ value, onChange }) => (
                                <RSInputPicker styles={{ width: 224, display: 'block', marginBottom: 10 }}
                                    data={salaryTypeList} value={value} onChange={onChange} style={{ width: 224 }} />

                                // <CompSelectSearch value={value} onChange={onChange} list={salaryTypeList} />
                            )}
                        />
                        <Typography variant="body1" sx={{ mx: 1 }}>-</Typography>
                        <RSTextField
                            name="salaryProType"
                            required={true}
                            label="Salary Process Type"
                            onChange={salaryProcessTypeChange}
                            accepter={({ value, onChange }) => (
                                <CompSelectSearch value={value} onChange={onChange} list={salaryProcessTypeList} />

                            )}
                        />

                    </Box>
                    <Box display="flex" alignItems="center" sx={{ grap: 3 }}>

                        <Typography variant="body1" sx={{ mx: 1 }}></Typography>
                        <RSTextField
                            name="minSalary"
                            required={true}
                            label="Min Salary"
                            onChange={minSalaryChange}
                            accepter={({ value, onChange }) => (
                                <CompSelectSearch value={value} onChange={onChange} list={salaryRangeList} />
                            )} />
                        <Typography variant="body1" sx={{ mx: 1 }}>to</Typography>
                        <RSTextField
                            name="maxSalary"
                            required={true}
                            label="Max Salary"
                            onChange={maxSalaryChange}
                            accepter={({ value, onChange }) => (
                                <CompSelectSearch value={value} onChange={onChange} list={salaryRangeList} />
                            )} />
                        <br />


                    </Box>
                    {!formValue.checkPercentage ? (
                        <Box>
                            <p>
                                Entire salary is fixed. It does not include any variable component.{" "}
                                <span
                                    style={{ color: "blue", cursor: "pointer" }}
                                    onClick={() =>
                                        setFormValue((prevState) => ({ ...prevState, checkPercentage: true }))
                                    }
                                >
                                    Change
                                </span>
                            </p>
                        </Box>
                    ) : (
                        <Box>
                            <Typography variant="h6">
                                Percentage of Salary that is Variable{" "}
                                <span
                                    style={{ color: "blue", cursor: "pointer" }}
                                    onClick={() =>
                                        setFormValue((prevState) => ({
                                            ...prevState, checkPercentage: false, percentage: '', variableMinSalary: '', variableMinSalary: '',
                                            variableMaxSalary: ''
                                        }))
                                    }
                                >
                                    Back
                                </span>
                            </Typography>
                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid item xs={6}>
                                    <RSTextField onChange={(e) => handlePercentageChanges(e, formValue.salaryType, formValue.minSalary, formValue.maxSalary)}
                                        min="0" name="percentage" label="Percentage Fixed" type="number" required={true} />

                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">
                                        Entire salary is fixed: <strong> {formValue.variableSalaryType} {formValue.variableMinSalary || 0}-{formValue.variableMaxSalary || 0}</strong>
                                    </Typography>

                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formValue.hideSalary}
                                onChange={handleHideSalaryChange}
                            />
                        }
                        label={
                            <Typography variant="body2" sx={{ fontSize: "0.875rem", color: "text.primary" }}>
                                Hide salary details from candidates
                            </Typography>
                        }
                    />
                    {formValue.hideSalary && (
                        <Box>
                            <Typography variant="body2" color="textSecondary">
                                Displaying salary invites higher number of responses. However, if you want to hide the salary,
                                please enter the correct salary to reach out to a relevant pool of candidates.
                            </Typography>
                        </Box>
                    )}


                    <RSTextField
                        name="companyIndustries"
                        required={true}
                        label="Company Industry"
                        accepter={({ value, onChange }) => (
                            <CompSelectSearch value={value} onChange={onChange} list={companyIndustriesList} />
                        )} />
                    <VStack>
                        <RSTextField
                            name="educations"
                            required={true}
                            label="Educational Qualification"
                            accepter={({ value, onChange }) => (
                                <CheckTreePicker
                                    value={value}
                                    onChange={(val) => {
                                        // Explicitly handle value change
                                        onChange(val);
                                    }}
                                    searchable

                                    data={educationOptionsList}
                                    defaultExpandAll
                                    cascade
                                    placeholder="Select Education Qualification"
                                    style={{ width: 280 }}
                                />

                            )}
                        />
                    </VStack>

                    <hr />
                    <RSTextField
                        name="candidateIndustries"
                        required={true}
                        label="Candidate Industries"
                        accepter={({ value, onChange }) => (
                            <CheckTreePicker
                                value={value}
                                onChange={onChange}
                                data={candidateIndustriesList}
                                defaultExpandAll
                                cascade
                                placeholder="Select Candidate Industries"
                                style={{ width: 280 }}
                            />
                            //     <TreePicker
                            //     value={value}
                            //     onChange={(val) => {
                            //         // Explicitly handle value change
                            //         onChange(val);
                            //     }}
                            //     data={educationOptionsList}
                            //     defaultExpandAll
                            //     style={{ width: 246 }}
                            //     searchable
                            //     blockNode
                            //     checkable // Enables checkboxes for multiple selection
                            //     cascade
                            // />
                        )}
                    />
                    <RSTextField
                        name="isDiversityHiringChecked"
                        required={false}
                        label="Diversity Hiring"
                        accepter={({ value, onChange }) => (
                            <>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={value}
                                        onChange={(_, isChecked) => {
                                            onChange(isChecked);
                                        }}
                                    />
                                    {/* Text */}
                                    <span style={{ marginLeft: '8px' }}>
                                        Hire women candidates for this role and promote diversity in the workplace.
                                    </span>


                                </div>
                                <div>
                                    <Typography variant="caption" color="textSecondary">
                                        Diversity hiring feature currently requires no additional credits.
                                    </Typography>
                                </div>
                            </>

                        )}
                    />

                    <Box sx={{ border: "1px solid #ccc", borderRadius: "5px", padding: "10px" }} >

                        <RSTextField
                            name="jobDescription"
                            required={true}
                            label="Job Description"
                            accepter={({ value, onChange }) => (
                                <JODITEditor
                                    label=""
                                    editor={noteRefer}
                                    content={value}
                                    placeholder={placeholderJobDescription}
                                    onBlurs={(content) => {
                                        onChange(content);
                                        // console.log(content);

                                    }}
                                />
                            )}
                        // value={jobDescription}
                        // onChange={setJobDescription}
                        />
                        <Form.Group>
                            <Form.ControlLabel>
                                Do you have more than one vacancy for this job?
                            </Form.ControlLabel>
                            <RSRadioGroup
                                name="vacancy"
                                inline
                                value={formValue.vacancy}
                                onChange={handleVacancyChanges}
                            >
                                <RSRadio value="true">Yes</RSRadio>
                                <RSRadio value="false">No</RSRadio>
                            </RSRadioGroup>
                        </Form.Group>

                        {formValue.vacancy === "true" && (
                            <RSTextField
                                name="additionalVacancy"
                                label="Enter the number of vacancies"
                                required={formValue.vacancy === "true"}
                                value={formValue.additionalVacancy}
                                onChange={(value) => setFormValue({ ...formValue, additionalVacancy: value })}
                            />
                        )}
                        <RSTextField
                            name="requestCandidateVideoProfile"
                            required={false}
                            accepter={({ value, onChange }) => (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            checked={value}
                                            onChange={(_, isChecked) => {
                                                onChange(isChecked);
                                            }}
                                        />
                                        {/* Text */}
                                        <span style={{ marginLeft: '8px' }}>
                                            Request candidates for video profile      </span>


                                    </div>
                                </>

                            )}
                        />
                        <RSTextField
                            name="requestIncludeWalkInDetails"
                            required={false}
                            accepter={({ value, onChange }) => (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            checked={value}
                                            onChange={(_, isChecked) => {
                                                onChange(isChecked);
                                            }}
                                        />
                                        {/* Text */}
                                        <span style={{ marginLeft: '8px' }}>
                                            Include walk-in details  </span>


                                    </div>
                                </>

                            )}
                        />
                        {formValue.requestIncludeWalkInDetails == true && (

                            <RSTextField rows={4} name="requestIncludeWalkInText" label="Enter Walk-in details" required={false} />
                        )}
                    </Box>



                    <AddQuestionsForm />
                    <Box sx={{ border: "1px solid #ccc", borderRadius: "5px", padding: "10px" }} >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: "bold",
                            }}
                            gutterBottom
                        >
                            Collaboration with other users
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Responses are only visible to you. Option to share access with sub-users via link.
                        </Typography>
                        <FormGroup sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3,
                            padding: "4px"
                        }}>
                            <Form.Group>
                                <RSTextField
                                    name="isSendMailChecked"
                                    required={false}
                                    // label="Diversity Hiring"
                                    accepter={({ value, onChange }) => (
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox
                                                    checked={value}
                                                    onChange={(_, isChecked) => {
                                                        onChange(isChecked);
                                                    }}
                                                />
                                                {/* Text */}
                                                <span style={{ marginLeft: '8px' }}>
                                                    Notify me about AI-recommended applicants through email</span>


                                            </div>

                                        </>

                                    )}
                                />


                                <small>On which email ids do you want to receive notifications of matching applies?</small>

                                {formValue.isSendMailChecked && (
                                    <>
                                        <RSCheckPicker

                                            data={companySubUserEmails}
                                            value={formValue.selectedUserEmails}
                                            onChange={(value) =>
                                                setFormValue({ ...formValue, selectedUserEmails: value })
                                            }
                                            placeholder="Select Emails"
                                            style={{ width: 224 }}

                                        />
                                        {formError.selectedUserEmails && (
                                            <div className="error">{formError.selectedUserEmails}</div>
                                        )}
                                    </>
                                )}
                            </Form.Group>
                            <RSTextField name="companyName" label="Company Name" required={true} />

                            <RSTextField name="companyWebsite" label="Company Website" required={false} />

                            <RSTextField name="companyEmail" label="Company Email" required={true} />
                            <RSTextField name="foundedYear" label="Founded Year" required={true} />
                            <RSTextField name="headquarters" label="Company headquarters" required={true} />

                            <RSTextField name="companyVideoContent" label="Company Upload Video" required={false} />
                            <label>Company Image Upload </label>
                            <input type="file" name="companyImageUpload" multiple onChange={handleImageUpload} ></input>
                            {/* <Uploader multiple
                                listType="picture-text"

                                name="companyImageUpload"
                                placeholder="Upload Company Images"
                                accept="image/*"
                                onChange={handleImageUpload}
                                // listType="picture"
                                draggable>
                                <Button sx="md">Company Images Select Upload...</Button>
                            </Uploader> */}

                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    overflowX: "auto",
                                    padding: "10px",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                }}
                            >

                                {formValue.companyImageUpload.length === 0 && <p>No images uploaded yet.</p>}
                                {formValue.companyImageUpload.map((image) => (
                                    <div
                                        key={image.id}
                                        style={{
                                            position: "relative",
                                            width: "120px",
                                            height: "120px",
                                            flex: "0 0 auto",
                                            border: "1px solid #ddd",
                                            borderRadius: "5px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <img
                                            src={image.src}
                                            alt="Uploaded"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                        <Whisper
                                            placement="top"
                                            trigger="hover"
                                            speaker={<Tooltip>Remove Image</Tooltip>}
                                        >
                                            <IconButton
                                                icon={<CloseIcon />}
                                                size="xs"
                                                circle
                                                appearance="subtle"
                                                style={{
                                                    position: "absolute",
                                                    top: "5px",
                                                    right: "5px",
                                                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                                                }}
                                                onClick={() => removeImage(image.id)}
                                            />
                                        </Whisper>
                                    </div>
                                ))}
                            </div>
                            {/* <ImageUploadPreviewRSuite /> */}
                            <RSTextField
                                name="companySize"
                                label="Company Size"
                                required={true}
                                accepter={({ value, onChange }) => (
                                    <CompSelectSearch value={value} onChange={onChange} list={companySizeList} />
                                )}
                            />

                            <RSTextField
                                name="aboutCompany"
                                required={true}
                                label="About Company"
                                accepter={({ value, onChange }) => (
                                    <JODITEditor
                                        label=""
                                        editor={noteReferAboutCompany}
                                        content={value}
                                        placeholder={placeholderCompInfo}
                                        onBlurs={(content) => {
                                            onChange(content);
                                        }}
                                    />
                                )}
                            // value={aboutCompany}
                            // onChange={(value) => setAboutCompany(value)}
                            />

                            <Form.Group>
                                <Form.ControlLabel>How often should mails be sent ?</Form.ControlLabel>
                                <RSSelectPicker
                                    name="notificationFrequency"
                                    data={notificationFrequencyLists}
                                    value={formValue.notificationValue}
                                    onChange={(value) =>
                                        setFormValue({ ...formValue, notificationValue: value })
                                    }
                                    placeholder="Choose frequency..."
                                    style={{ width: 224 }}
                                />
                                {formError.notificationValue && (
                                    <div className="error">{formError.notificationValue}</div>
                                )}
                            </Form.Group>
                            <Form.Group>

                                <RSTextField name="socialLinkFB" label="facebook link" required={false} />
                                <RSTextField name="socialLinkIn" label="linkedin link" required={false} />
                                <RSTextField name="socialLinkTw" label="twitter link" required={false} />
                                <RSTextField name="socialLinkIns" label="instagram link" required={false} />
                                <RSTextField name="socialLinkYou" label="youtube link" required={false} />

                            </Form.Group>
                        </FormGroup>
                    </Box >
                </Form>

                {/* </FlexboxGrid.Item> */}
                {/* <FlexboxGrid.Item colspan={12}>
                        <JSONView formValue={formValue} formError={formError} />
                    </FlexboxGrid.Item> */}
                {/* </FlexboxGrid> */}
            </Grid >


            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                {/* <Button variant="contained" color="secondary" onClick={handleSubmit} >
                    Testing Data
                </Button> */}
                <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                    Save and Continue
                </Button>
            </Box>
        </Box >
    );
};

export default PostJobFormVacancy;
