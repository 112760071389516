import React from "react";
import Breadcrumb from "../../components/breadcrumb";
import SignUp from "../../components/signUp";
import { useState } from "react";

function CreateAccount(props) {
  return (
    <>
      <Breadcrumb title="Login" />
      <SignUp />
    </>
  );
}

export default CreateAccount;
