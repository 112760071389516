import React from "react";
import { Navigate } from "react-router-dom";

const AuthMiddleware = ({ children, allowedRoles = [] }) => {
  const token = localStorage.getItem("token"); // Replace with your auth logic
  const userRole = localStorage.getItem("user"); // Optional: Role-based access
  const userAdmin = localStorage.getItem("employer");
  if (!token || (allowedRoles.length && !allowedRoles.includes(userRole))) {
    return <Navigate to="/login" replace />;
  }
  if (!token || (allowedRoles.length && !allowedRoles.includes(userAdmin))) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthMiddleware;
