import React, { useState, useEffect } from 'react';
import { Button, Dialog, Box, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, Checkbox, Grid, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from '../../api/axios';
import domain from "../../api/constent";
import Session from '../../api/session';

import { ShimmerDiv, ShimmerCategoryItems } from "shimmer-effects-react";

import apiService from "../../api/apiService";

import { sweetAlert } from '../../utils/sweetAlert';

// Main Component
export default function LicensesAComp() {
    const [openDialog, setOpenDialog] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);  // To toggle between Create and Edit mode
    const [formData, setFormData] = useState({
        pid: 0,
        licenseName: '',
        issuingOrganisation: '',
        issueDate: '',
        expiryDate: '',
        noExpiry: false,
        description: ''
    });

    const [errors, setErrors] = useState({
        licenseName: '',
        issuingOrganisation: '',
        issueDate: '',
        expiryDate: '',
        description: ''
    });

    const [licenses, setLicenses] = useState([]);  // State to hold the list data
    const [isLoading, setLoading] = useState(true);  // Loading state

    useEffect(() => {
        fetchLicensesAComp();
    }, []);


    const handleChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
        if (value) {
            setErrors({ ...errors, [field]: '' });
        }
    };

    const handleOpenDialog = (license = null) => {
        if (license) {
            // Editing an existing license
            setIsEditMode(true);
            setFormData({
                pid: license.id,
                licenseName: license.license_name,
                issuingOrganisation: license.issue_organization,
                issueDate: license.issue_date,
                expiryDate: license.no_expiry ? '' : license.expiry_date,
                noExpiry: license.no_expiry,
                description: license.description
            });
        } else {
            // Creating a new license
            setIsEditMode(false);
            setFormData({
                licenseName: '',
                issuingOrganisation: '',
                issueDate: '',
                expiryDate: '',
                noExpiry: false,
                description: ''
            });
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSave = () => {
        const newErrors = {};
        if (!formData.licenseName) newErrors.licenseName = 'License Name is required';
        if (!formData.issuingOrganisation) newErrors.issuingOrganisation = 'Issuing Organisation is required';
        if (!formData.issueDate) newErrors.issueDate = 'Issue Date is required';
        if (!formData.noExpiry && !formData.expiryDate) newErrors.expiryDate = 'Expiry Date is required';
        if (!formData.description) newErrors.description = 'Description is required';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            try {

                if (isEditMode) {
                    // Create License API call
                    const formDataInsert2 = {
                        p_id: formData.pid,
                        registration_id: Session.getDataSessionStore().id,
                        license_name: formData.licenseName,
                        issue_organization: formData.issuingOrganisation,
                        issue_date: formData.issueDate,
                        expiry_date: formData.expiryDate,
                        no_expiry: formData.noExpiry,
                        description: formData.description
                    }
                    setLoading(true);
                    // Update License API call
                    axios.post(`${domain.Candidate_Licenses_Edit}${formDataInsert2.p_id}`, formDataInsert2)
                        .then(response => {
                            setLicenses(licenses.map(license => license.id === formDataInsert2.p_id ? response.data : license));
                            setOpenDialog(false);
                        })
                        .catch(error => {
                            console.error('Error updating license:', error);
                        });
                    sweetAlert('Success!', 'Your create license has been Updated.', 1);
                    fetchLicensesAComp();

                } else {
                    // Create License API call
                    const formDataInsert = {
                        registration_id: Session.getDataSessionStore().id,
                        license_name: formData.licenseName,
                        issue_organization: formData.issuingOrganisation,
                        issue_date: formData.issueDate,
                        expiry_date: formData.expiryDate,
                        no_expiry: formData.noExpiry,
                        description: formData.description
                    }
                    setLoading(true);
                    axios.post(domain.Candidate_Licenses_Create, formDataInsert)
                        .then(response => {
                            setLicenses([...licenses, response.data]);
                            setOpenDialog(false);
                        })
                        .catch(error => {
                            console.error('Error creating license:', error);
                        });
                    sweetAlert('Success!', 'Your create license has been insert.', 1);
                    fetchLicensesAComp();
                    setLoading(false);
                }
            } catch (error) {

                sweetAlert('Error!', 'Failed to create license details. Try again.', 2);

            }
        }
    };
    const fetchLicensesAComp = async () => {
        const sessionData = Session.getDataSessionStore();
        setLoading(true);
        // Check if sessionData exists and has required properties
        if (sessionData && sessionData.id && sessionData.email) {
            const { id: userId, email: userEmail } = sessionData;

            // Fetch data from API on component mount
            axios.get(`${domain.Licenses_List}${userId}`)
                .then(response => {
                    setLicenses(response.data.data); // Set list data from the API response
                    setLoading(false); // Set loading to false once data is fetched
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        } else {
            console.error('Session data is missing or incomplete');
            setLoading(false);
        }
    };

    const styles = {
        card: {
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '16px',
            margin: '16px 0',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        licenseName: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        editButton: {
            background: 'transparent',
            border: 'none',
            fontSize: '18px',
            cursor: 'pointer',
        },
        verifyLink: {
            color: '#007bff',
            textDecoration: 'none',
            fontSize: '16px',
        },
        institution: {
            fontSize: '16px',
            color: '#555',
        },
        description: {
            fontSize: '14px',
            color: '#333',
        },
        expiryDate: {
            fontSize: '14px',
            color: '#888',
        },
        finishedYear: {
            fontSize: '14px',
            color: '#888',
        },
    }

    return (
        <div>
            <div className="pt-2">
                <strong>Licenses & Certifications</strong>
                <p>Showcase your professional credentials. Add your relevant licenses, certificates, memberships, and accreditations here.</p>
                {isLoading ? (
                    // Shimmer effect for loading state
                    <ShimmerCategoryItems mode="light" />

                ) : (
                    licenses.map((license) => (
                        <div style={styles.card} key={license.id}>
                            <div style={styles.header}>
                                <h3 style={styles.licenseName}>{license.license_name}</h3>
                                <button style={styles.editButton} onClick={() => handleOpenDialog(license)} title="Edit">
                                    ✏️
                                </button>
                            </div>
                            <p style={styles.institution}>{license.issue_organization}</p>
                            <p style={styles.finishedYear}>
                                Issued: {new Date(license.issue_date).toLocaleDateString()}
                            </p>
                            <p style={styles.expiryDate}>
                                Expiry Date: {license.no_expiry ? 'No Expiry' : new Date(license.expiry_date).toLocaleDateString()}
                            </p>
                            <p style={styles.description}>{license.description}</p>
                        </div>
                    ))
                )}
                <Button size="small" variant="contained" onClick={() => handleOpenDialog()}>Add Licenses & Certifications</Button>
            </div>

            {/* Licenses & Certifications Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <strong>{isEditMode ? 'Edit License & Certification' : 'Add License & Certification'}</strong>
                        <IconButton edge="end" color="inherit" onClick={handleCloseDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        {/* License Name */}
                        <Grid item xs={12}>
                            <TextField
                                label="License Name"
                                variant="outlined"
                                fullWidth
                                value={formData.licenseName}
                                onChange={(e) => handleChange('licenseName', e.target.value)}
                                error={!!errors.licenseName}
                                helperText={errors.licenseName || 'Enter the name of the license or certification'}
                                placeholder="Enter License Name"
                            />
                        </Grid>

                        {/* Issuing Organisation */}
                        <Grid item xs={12}>
                            <TextField
                                label="Issuing Organisation"
                                variant="outlined"
                                fullWidth
                                value={formData.issuingOrganisation}
                                onChange={(e) => handleChange('issuingOrganisation', e.target.value)}
                                error={!!errors.issuingOrganisation}
                                helperText={errors.issuingOrganisation || 'Enter the name of the issuing organisation'}
                                placeholder="Enter Issuing Organisation"
                            />
                        </Grid>

                        {/* Issue Date */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Issue Date"
                                variant="outlined"
                                fullWidth
                                type="date"
                                value={formData.issueDate}
                                onChange={(e) => handleChange('issueDate', e.target.value)}
                                error={!!errors.issueDate}
                                helperText={errors.issueDate || 'Select the issue date'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        {/* Expiry Date */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Expiry Date"
                                variant="outlined"
                                fullWidth
                                type="date"
                                disabled={formData.noExpiry}
                                value={formData.expiryDate}
                                onChange={(e) => handleChange('expiryDate', e.target.value)}
                                error={!!errors.expiryDate}
                                helperText={errors.expiryDate || 'Select the expiry date'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        {/* No Expiry Checkbox */}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.noExpiry}
                                        onChange={(e) => handleChange('noExpiry', e.target.checked)}
                                    />
                                }
                                label="No Expiry"
                            />
                        </Grid>

                        {/* Description */}
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                variant="outlined"
                                fullWidth
                                value={formData.description}
                                onChange={(e) => handleChange('description', e.target.value)}
                                error={!!errors.description}
                                helperText={errors.description || 'Enter a brief description'}
                                placeholder="Enter Description"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleSave} variant="contained" color="primary">
                        {isEditMode ? 'Update' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
