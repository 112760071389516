import { React, lazy } from "react";
import CandidateDashboard from "../../pages/Home/HomePage";
import CandidateProfile from "../../pages/Profile/ProfilePage";
import CandidateCardPage from "../../components/card-style/cardComp";
import Login from "../../pages/Auth/Login";
import CreateAccount from "../../pages/Auth/CreateAccount";

const routesConfigCandidate = [
    { path: "/", element: <CandidateDashboard />, isProtected: false },
    { path: "/login", element: <Login />, isProtected: false },
    { path: "/create-account", element: <CreateAccount />, isProtected: false },
    { path: "/profile", element: <CandidateProfile />, isProtected: true },
    { path: "/card", element: <CandidateCardPage />, isProtected: true },
];

export default routesConfigCandidate;