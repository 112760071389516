import React from "react";

import Breadcrumb from "../../components/breadcrumb";
import SignIn from "../../components/signin";

function Login(props) {
  return (
    <>
      <Breadcrumb title="Login" />
      <SignIn />

    </>
  );
}

export default Login;
