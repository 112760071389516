import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Gallery from "../popup/Gallery";
import Video from "../popup/Video";
import Session from "../../api/session";
// Profile Component
import ProfileModel from "../candidates/CandidateProfile";
import { Modal, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Typography,
  TextField,
  Button,
} from '@mui/material';

// steps form
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import cardStyles from '../../assets/css/card-style/card.css';
import CardComp from "../../components/card-style/cardComp";
import RoleComp from '../../components/Role/RoleComp';
import ProfilePage from "../../pages/Profile/ProfilePage";
import axios from "../../api/axios";
import img from "../../assets/images/review/google.png";
import img2 from "../../assets/images/review/tweet.png";
import domain from "../../api/constent";
import { sweetAlert } from '../../utils/sweetAlert';

import ReviewSubmit from "../../components/reviewSubmit/ReviewSubmit";
import apiService from "../../api/apiService";

const steps = [
  'Choose a Document',
  'Answer Employee Queries',
  'Update Profile',
  'Review and Submit'
];
TabJob.propTypes = {};

// file upload
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: {
    xs: '90%',
    sm: '80%',
    md: '60%',
    lg: '50%',
    xl: '40%'
  },
  maxHeight: '70vh',
  overflowY: 'auto'
};

function TabJob(props) {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  const [modalContent, setModalContent] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [formData, setFormData] = useState({
    compId: 0,
    checkResume: 0,
    uploadResume: false,
    selectOptionResume: false,
    notSelectResume: false,
    checkCoverLetter: 0,
    uploadCoverLetter: false,
    selectOptionCoverLetter: false,
    notSelectCoverLetter: false,
    selectedResume: null,
    selectedCoverLetter: null
  });


  const [selectOptionResume, setSelectOptionResume] = useState([]);
  const [selectOptionCoverLetter, setSelectOptionCoverLetter] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getPreviousUploadedResume();
    getPreviousUploadedCoverLetter();
  }, []);

  const getPreviousUploadedResume = () => {
    setSelectOptionResume(['Resume 1', 'Resume 2', 'Resume 3']);
  };
  const getPreviousUploadedCoverLetter = () => {
    setSelectOptionCoverLetter(['Cover Letter 1', 'Cover Letter 2', 'Cover Letter 3']);
  };

  const handleRadioChange = (event) => {
    const value = parseInt(event.target.value);
    if (value >= 1 && value <= 3) {
      setFormData((prev) => ({
        ...prev,
        checkResume: value,
        uploadResume: value === 1,
        selectOptionResume: value === 2,
        notSelectResume: value === 3,
      }));
    } else if (value >= 4 && value <= 6) {
      setFormData((prev) => ({
        ...prev,
        checkCoverLetter: value,
        uploadCoverLetter: value === 4,
        selectOptionCoverLetter: value === 5,
        notSelectCoverLetter: value === 6,
      }));
    }

  };


  const handleFileChangeResume = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, selectedResume: file }));
    console.log('Selected Resume:', file);
  };

  const handleSelectResumeChange = (selectedValue) => {
    setFormData((prev) => ({ ...prev, selectedResume: selectedValue }));

  };

  // Cover letter
  const handleFileChangeCoverLetter = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, selectedCoverLetter: file }));
    console.log('Selected Cover Letter:', file);
  };

  const handleSelectCoverLetterChange = (selectedValue) => {
    setFormData((prev) => ({ ...prev, selectedCoverLetter: selectedValue }));
  };



  function handleRedirect(data) {
    if (Session.getChecking()) {
      handleOpen(data);
    } else {
      window.location.href = "/login";
    }
  }


  const handleOpen = (value) => {
    setModalContent(value);
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false)
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep === steps.length - 1) {
      handleSubmitted(); // Call the submit function on the last step
    }

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  // main submit
  const handleSubmitted = () => {
    handleUpload();
    console.log(formData);
  }

  // step2
  const handleUpload = async (e) => {
    // e.preventDefault();


    // // Check if file exists
    if (!formData.selectedResume || !formData.selectedCoverLetter) {
      setMessage("Please select a file to upload. Upload Resume");
      return;
    }

    // Validate file type and size (Optional)
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
    const maxSize = 5 * 1024 * 1024; // 5 MB

    if (!allowedTypes.includes(formData.selectedResume.type)) {
      setMessage("Invalid file type. Please upload a PDF, JPG, or PNG.");
      return;
    }
    if (!allowedTypes.includes(formData.selectedCoverLetter.type)) {
      setMessage("Invalid file type. Please upload a PDF, JPG, or PNG.");
      return;
    }

    if (formData.selectedResume.size > maxSize) {
      setMessage("File size exceeds the maximum limit of 5 MB.");
      return;
    }

    // Prepare form data
    const formLoad = new FormData();
    formLoad.append("candidate_id", Session.getDataSessionStore()?.id || "");
    formLoad.append("company_id", "1");
    formLoad.append("resume", formData.selectedResume);
    formLoad.append("cover_letter", formData.selectedCoverLetter);


    try {
      // Upload the file using axios
      const response = await axios.post(domain.Apply_Job, formLoad, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        sweetAlert('Success!', 'Your Job Apply has been Successfully.', 1);

        console.log("File upload successful:", response.data);
        setMessage(response.data.message || "File uploaded successfully.");
      } else {
        console.warn("Unexpected response:", response);
        setMessage("Unexpected response during file upload.");
        sweetAlert('Error!', response, 2);
      }
    } catch (error) {
      console.error("Error uploading file:", error);

      sweetAlert('Error!', 'Failed to create Apply job Please. Try again.', 2);

      setMessage("File upload failed. Please try again.");
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <div class="pt-3">
          <div className="row ">
            <CardComp />
            <div class="row p-3">
              <Box >
                <FormControl >
                  <RadioGroup
                    row
                    sx={{ flexDirection: "column", gap: 2 }}
                    value={formData.checkResume}
                    onChange={handleRadioChange}
                  >

                    <FormLabel >Select Resume Option</FormLabel>

                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Upload Resume"
                    />
                    {formData.uploadResume && (
                      <div>
                        <TextField
                          type="file"
                          label="Upload Resume"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          onChange={(e) => handleFileChangeResume(e)} // Automatically trigger file upload on change
                        />
                      </div>
                    )}

                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Select From Options"
                    />
                    {formData.selectOptionResume && (
                      <FormControl fullWidth>
                        <InputLabel id="select-resume-label">Select Resume</InputLabel>
                        <Select
                          labelId="select-resume-label"
                          onChange={(e) => handleSelectResumeChange(e)} // Trigger the change handler

                        >
                          {selectOptionResume.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="Not Selecting Resume"
                    />
                    {formData.notSelectResume && (
                      <div>
                        <p>You have chosen not to select a resume.</p>
                      </div>
                    )}
                  </RadioGroup>
                </FormControl>
              </Box>
            </div>
            {/* Cover Letter */}
            <div class="row p-3">
              <Box >
                <FormControl >
                  <FormLabel>Select Cover Letter Option</FormLabel>
                  <RadioGroup
                    row
                    sx={{ flexDirection: "column", gap: 2 }}

                    value={formData.checkCoverLetter}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label="Upload Cover Letter"
                    />
                    <div>
                      <TextField
                        type="file"
                        label="Upload Cover Letter"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={(e) => handleFileChangeCoverLetter(e)} // Automatically trigger file upload on change
                      />

                    </div>
                    <FormControlLabel
                      value={5}
                      control={<Radio />}
                      label="Select From Options"
                    />
                    {formData.selectOptionCoverLetter && (
                      <FormControl fullWidth>
                        <InputLabel id="select-resume-label">Select Cover Letter</InputLabel>
                        <Select
                          labelId="select-resume-label"
                          onChange={(e) => handleSelectCoverLetterChange(e)} // Trigger the change handler
                        >
                          {selectOptionCoverLetter.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <FormControlLabel
                      value={6}
                      control={<Radio />}
                      label="Not Selecting Cover Letter"
                    />
                    {formData.notSelectCoverLetter && (
                      <div>
                        <p>You have chosen not to select a Cover Letter.</p>
                      </div>
                    )}

                  </RadioGroup>
                </FormControl>
              </Box>
            </div>
          </div >
        </div >;
      case 1:
        return <div class=" pt-3">
          <strong>Please answer the following queries:</strong>
          {/* <div>
            <label htmlFor="work-right">Which of the following statements best describes your right to work in Australia?</label>
            <select id="work-right" >
              <option value="">Select an option</option>
              <option value="permanent-resident-nz-citizen">I'm a permanent resident and/or NZ citizen</option>
            </select>
          </div>
          <div>
            <label htmlFor="project-manager-experience">How many years' experience do you have as a project manager?</label>
            <input
              type="number"
              id="project-manager-experience"

            />
          </div>
          <div>
            <label htmlFor="project-management-experience">How many years of project management experience do you have?</label>
            <input
              type="number"
              id="project-management-experience"
            />
          </div> */}
        </div>;

      case 2:
        return <div class="pt-2">
          <div>
            <ProfilePage />
          </div>
        </div>


      case 3:
        return <div class="pt-2">
          <div className="">

            <ReviewSubmit />
          </div>


        </div >

      default:
        return <div>Unknown step</div>;
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };



  return (
    <section className="tab-section" >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: '80%', maxWidth: '800px' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">Applying for </Typography>
          <h4>{modalContent.title}</h4>
          <p>{modalContent.cate}</p>
          <Typography id="modal-modal-title" variant="h6" component="h4">view job details </Typography>

          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              // if (isStepOptional(index)) {
              //   labelProps.optional = (
              //     <Typography variant="caption">Optional</Typography>
              //   );
              // }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button variant="contained" onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {renderStepContent(activeStep)} {/* Render the content for the current step */}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                <Button variant="contained" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Submit' : 'Continue'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Modal>
      <div className="tf-container">
        <Tabs className="row tf-tab">
          <div className="col-xl-5 col-lg-5 col-md-12">
            <div className="group-job-sidebar">
              <TabList className="list-job menu-tab">
                {data.map((idx) => (
                  <Tab key={idx.id} className="features-job mg-bt-0 ct-tab">
                    <div className="job-archive-header">
                      <div className="inner-box">
                        <div className="logo-company">
                          <img src={idx.img} alt="Jobtex" />
                        </div>
                        <div className="box-content">
                          <h4>
                            <Link to="/Jobsingle_v1">{idx.cate}</Link>
                          </h4>
                          <h3>
                            <Link to="/Jobsingle_v1">{idx.title}</Link>&nbsp;
                            <span className="icon-bolt"></span>
                          </h3>
                          <ul>
                            <li>
                              <span className="icon-map-pin"></span>
                              {idx.map}
                            </li>
                            <li>
                              <span className="icon-calendar"></span>
                              {idx.time}
                            </li>
                          </ul>
                          <span className="icon-heart"></span>
                        </div>
                      </div>
                    </div>
                    <div className="job-archive-footer">
                      <div className="job-footer-left">
                        <ul className="job-tag">
                          <li>
                            <Link to="#">    {idx.jobs1}</Link>{" "}
                          </li>
                          <li>
                            <Link to="#">    {idx.jobs2}</Link>{" "}
                          </li>
                        </ul>
                        <div className="star">
                          <span className="icon-star-full"></span>
                          <span className="icon-star-full"></span>
                          <span className="icon-star-full"></span>
                          <span className="icon-star-full"></span>
                          <span className="icon-star-full"></span>
                        </div>
                      </div>
                      <div className="job-footer-right">
                        <div className="price">
                          <span className="icon-dolar1"></span>
                          <p>
                            {idx.price} <span className="year">/year</span>
                          </p>
                        </div>
                        <p className="days">{idx.apply}</p>
                      </div>
                    </div>
                  </Tab>
                ))}

                <Link to="#" className="btn-load">
                  Load more Job Results (3719)
                </Link>
              </TabList>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-12" id="zIndex">
            {data.map((idx) => (
              <TabPanel className="content-tab sticky-home2 po-sticky add-mgt">
                <article className={`job-article ${idx.stt}`}>
                  <div className="top-content">
                    <div className="features-job style-2 stc-apply">
                      <div className="job-archive-header">
                        <div className="inner-box">
                          <div className="logo-company">
                            <img src={idx.img} alt="Jobtex" />
                          </div>
                          <div className="box-content">
                            <h4>
                              <Link to="/Jobsingle_v1">
                                {idx.cate}
                              </Link>
                            </h4>
                            <h3>
                              <Link to="/Jobsingle_v1">
                                {idx.title}
                              </Link>
                              &nbsp;
                              <span className="icon-bolt"></span>
                            </h3>
                            <ul>
                              <li>
                                <span className="icon-map-pin"></span>
                                {idx.map}
                              </li>
                              <li>
                                <span className="icon-calendar"></span>
                                {idx.time}

                              </li>
                            </ul>
                            <div className="button-readmore">
                              <span className="icon-heart"></span>
                              <Link
                                className="btn-apply btn-popup"
                                onClick={() => handleRedirect({ title: idx.title, cate: idx.cate })}

                              >
                                <span className="icon-send" ></span>
                                Apply Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="job-archive-footer">
                        <div className="job-footer-left">
                          <ul className="job-tag">
                            <li>
                              <Link to="#"> {idx.jobs1}
                              </Link>
                            </li>
                            <li>
                              <Link to="#">  {idx.jobs2}
                              </Link>
                            </li>
                          </ul>
                          <div className="star">
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                            <span className="icon-star-full"></span>
                          </div>
                        </div>
                        <div className="job-footer-right">
                          <div className="price">
                            <span className="icon-dollar"></span>
                            <p>
                              {idx.price}{" "}
                              <span className="year">/year</span>
                            </p>
                          </div>
                          <p className="days">{idx.apply}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inner-content home2">
                    <h5>Full Job Description</h5>
                    <p>
                      Are you a User Experience Designer with a track record of
                      delivering intuitive digital experiences that drive
                      results? Are you a strategic storyteller and systems
                      thinker who can concept and craft smart, world-class
                      campaigns across a variety of mediums?
                    </p>
                    <p className="mg-19">
                      Deloitte's Green Dot Agency is looking to add a Lead User
                      Experience Designer to our experience design team. We want
                      a passionate creative who's inspired by new trends and
                      emerging technologies, and is able to integrate them into
                      memorable user experiences. A problem solver who is
                      entrepreneurial, collaborative, hungry, and humble; can
                      deliver beautifully designed, leading-edge experiences
                      under tight deadlines; and who has demonstrated proven
                      expertise.
                    </p>
                    <h6>The Work You'll Do:</h6>
                    <ul className="list-dot">
                      <li>
                        Support the Creative Directors and Associate Creative
                        Directors of experience design to concept and oversee
                        the production of bold, innovative, award-winning
                        campaigns and digital experiences.
                      </li>
                      <li>
                        Make strategic and tactical UX decisions related to
                        design and usability as well as features and functions.
                      </li>
                      <li>
                        Creates low- and high-fidelity wireframes that represent
                        a user's joGurney.
                      </li>
                      <li>
                        Effectively pitch wireframes to and solutions to
                        stakeholders. You'll be the greatest advocate for our
                        work, but you'll also listen and internalize feedback so
                        that we can come back with creative that exceeds
                        expectations.
                      </li>
                    </ul>
                    <h6>What you'll bring:</h6>
                    <ul className="list-dot mg-bt-15">
                      <li>
                        Passion for Human-Centered Design-a drive to make
                        interactive technology better for people.
                      </li>
                      <li>Thorough knowledge of UX/UI best practices.</li>
                      <li>
                        Understanding of brand identity and working within a
                        defined design system as well as contributing to it.
                      </li>
                      <li>
                        A mastery of craft. You dream about color, typography,
                        and interaction design every day. You are proficient
                        using tools like Figma and Adobe XD. You can efficiently
                        use your skill set to develop new designs within
                        existing and new visual systems and design languages.
                      </li>
                      <li>
                        A portfolio which highlights strong understanding of UX
                        design including but not limited to: user flows, IA, and
                        translating customer research, analytics, and insights
                        into wireframes and high-fidelity designs.
                      </li>
                      <li>
                        Possess problem-solving skills, an investigative
                        mentality, and a proactive nature-committed to
                        delivering solutions.
                      </li>
                      <li>Possess problem-solving skills</li>
                    </ul>
                    <h6>Qualifications:</h6>
                    <ul className="list-dot mg-bt-15">
                      <li>
                        Bachelor's degree preferred, or equivalent experience.
                      </li>
                      <li>
                        At least 5-8 years of experience with UX and UI design.
                      </li>
                      <li>
                        2 years of experience with design thinking or similar
                        framework that focuses on defining users' needs early.
                      </li>
                      <li>
                        Strong portfolio showing expert concept, layout, and
                        typographic skills, as well as creativity and ability to
                        adhere to brand standards.
                      </li>
                      <li>
                        Expertise in Figma, Adobe Creative Cloud suite,
                        Microsoft suite.
                      </li>
                      <li>
                        Ability to collaborate well with cross-disciplinary
                        agency team and stakeholders at all levels.
                      </li>
                      <li>
                        Forever learning: Relentless desire to learn and
                        leverage the latest web technologies.
                      </li>
                      <li>
                        Detail-oriented: You must be highly organized, be able
                        to multi-task, and meet tight deadlines.
                      </li>
                      <li>
                        Independence: The ability to make things happen with
                        limited direction. Excellent proactive attitude,
                        take-charge personality, and "can-do" demeanor.
                      </li>
                      <li>
                        Proficiency with Front-End UI technologies a bonus but
                        not necessary (such as HTML, CSS, JavaScript).
                      </li>
                    </ul>
                    <p>
                      For individuals assigned and/or hired to work in Colorado
                      or Nevada, Deloitte is required by law to include a
                      reasonable estimate of the compensation range for this
                      role. This compensation range is specific to the State of
                      Colorado and the State of Nevada and takes into account
                      the wide range of factors that are considered in making
                      compensation decisions including but not limited to skill
                      sets; experience and training; licensure and
                      certifications; and other business and organizational
                      needs. The disclosed range estimate has not been adjusted
                      for the applicable geographic differential associated with
                      the location at which the position may be filled. At
                      Deloitte, it is not typical for an individual to be hired
                      at or near the top of the range for their role and
                      compensation decisions are dependent on the facts and
                      circumstances of each case. A reasonable estimate of the
                      current range is $86425- $177470.
                    </p>
                    <p>
                      You may also be eligible to participate in a discretionary
                      annual incentive program, subject to the rules governing
                      the program, whereby an award, if any, depends on various
                      factors, including, without limitation, individual and
                      organizational performance.
                    </p>
                    <div className="post-navigation d-flex aln-center">
                      <div className="wd-social d-flex aln-center">
                        <span>Social Profiles:</span>
                        <ul className="list-social d-flex aln-center">
                          <li>
                            <Link to="#">
                              <i className="icon-facebook"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-linkedin2"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-twitter"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-pinterest"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-instagram1"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-youtube"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <Link to="#" className="frag-btn">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="15"
                          viewBox="0 0 14 15"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H13C13.1857 0 13.3678 0.0517147 13.5257 0.149349C13.6837 0.246984 13.8114 0.386681 13.8944 0.552786C13.9775 0.718892 14.0126 0.904844 13.996 1.08981C13.9793 1.27477 13.9114 1.45143 13.8 1.6L11.25 5L13.8 8.4C13.9114 8.54857 13.9793 8.72523 13.996 8.91019C14.0126 9.09516 13.9775 9.28111 13.8944 9.44721C13.8114 9.61332 13.6837 9.75302 13.5257 9.85065C13.3678 9.94829 13.1857 10 13 10H3C2.73478 10 2.48043 10.1054 2.29289 10.2929C2.10536 10.4804 2 10.7348 2 11V14C2 14.2652 1.89464 14.5196 1.70711 14.7071C1.51957 14.8946 1.26522 15 1 15C0.734784 15 0.48043 14.8946 0.292893 14.7071C0.105357 14.5196 0 14.2652 0 14V3Z"
                            fill="#64666C"
                          />
                        </svg>
                        Report job
                      </Link>
                    </div>
                    <div className="video-thumb">
                      <div className="content-tab2">
                        <div className="inner">
                          <div className="thumb">
                            <img
                              src={require("../../assets/images/review/thumbv3.jpg")}
                              alt="images"
                            />
                            <Video />
                          </div>
                        </div>
                      </div>
                      <Gallery />
                    </div>

                    <div className="related-job">
                      <h6>Related Jobs</h6>
                      <div className="features-job mg-bt-0">
                        <div className="job-archive-header">
                          <div className="inner-box">
                            <div className="logo-company">
                              <img
                                src={require("../../assets/images/logo-company/cty2.png")}
                                alt="jobtex"
                              />
                            </div>
                            <div className="box-content">
                              <h4>
                                <Link to="/Jobsingle_v1">
                                  Tamari Law Group LLC
                                </Link>
                              </h4>
                              <h3>
                                <Link to="/Jobsingle_v1">
                                  HR Administration
                                </Link>
                                <span className="icon-bolt"></span>
                              </h3>
                              <ul>
                                <li>
                                  <span className="icon-map-pin"></span>
                                  Las Vegas, NV 89107, USA
                                </li>
                                <li>
                                  <span className="icon-calendar"></span>2 days
                                  ago
                                </li>
                              </ul>
                              <span className="icon-heart"></span>
                            </div>
                          </div>
                        </div>
                        <div className="job-archive-footer">
                          <div className="job-footer-left">
                            <ul className="job-tag">
                              <li>
                                <Link to="#"> Part-time</Link>
                              </li>
                              <li>
                                <Link to="$"> Remote</Link>
                              </li>
                            </ul>
                            <div className="star">
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                            </div>
                          </div>
                          <div className="job-footer-right">
                            <div className="price">
                              <span className="icon-dolar1"></span>
                              <p>
                                $83,000 - $110,000{" "}
                                <span className="year">/year</span>
                              </p>
                            </div>
                            <p className="days">24 days left to apply</p>
                          </div>
                        </div>
                      </div>
                      <div className="features-job mg-bt-0">
                        <div className="job-archive-header">
                          <div className="inner-box">
                            <div className="logo-company">
                              <img
                                src={require("../../assets/images/logo-company/cty7.png")}
                                alt="jobtex"
                              />
                            </div>
                            <div className="box-content">
                              <h4>
                                <Link to="/Jobsingle_v1">
                                  Tamari Law Group LLC
                                </Link>
                              </h4>
                              <h3>
                                <Link to="/Jobsingle_v1">
                                  HR Administration
                                </Link>
                                <span className="icon-bolt"></span>
                              </h3>
                              <ul>
                                <li>
                                  <span className="icon-map-pin"></span>
                                  Las Vegas, NV 89107, USA
                                </li>
                                <li>
                                  <span className="icon-calendar"></span>2 days
                                  ago
                                </li>
                              </ul>
                              <span className="icon-heart"></span>
                            </div>
                          </div>
                        </div>
                        <div className="job-archive-footer">
                          <div className="job-footer-left">
                            <ul className="job-tag">
                              <li>
                                <Link to="#"> Part-time</Link>
                              </li>
                              <li>
                                <Link to="#"> Remote</Link>
                              </li>
                            </ul>
                            <div className="star">
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                            </div>
                          </div>
                          <div className="job-footer-right">
                            <div className="price">
                              <span className="icon-dolar1"></span>
                              <p>
                                $83,000 - $110,000{" "}
                                <span className="year">/year</span>
                              </p>
                            </div>
                            <p className="days">24 days left to apply</p>
                          </div>
                        </div>
                      </div>
                      <div className="features-job mg-bt-0">
                        <div className="job-archive-header">
                          <div className="inner-box">
                            <div className="logo-company">
                              <img
                                src={require("../../assets/images/logo-company/cty8.png")}
                                alt="jobtex"
                              />
                            </div>
                            <div className="box-content">
                              <h4>
                                <Link to="/Jobsingle_v1">
                                  Tamari Law Group LLC
                                </Link>
                              </h4>
                              <h3>
                                <Link to="/Jobsingle_v1">
                                  HR Administration
                                </Link>
                                <span className="icon-bolt"></span>
                              </h3>
                              <ul>
                                <li>
                                  <span className="icon-map-pin"></span>
                                  Las Vegas, NV 89107, USA
                                </li>
                                <li>
                                  <span className="icon-calendar"></span>2 days
                                  ago
                                </li>
                              </ul>
                              <span className="icon-heart"></span>
                            </div>
                          </div>
                        </div>
                        <div className="job-archive-footer">
                          <div className="job-footer-left">
                            <ul className="job-tag">
                              <li>
                                <Link to="#"> Part-time</Link>
                              </li>
                              <li>
                                <Link to="#"> Remote</Link>
                              </li>
                            </ul>
                            <div className="star">
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                            </div>
                          </div>
                          <div className="job-footer-right">
                            <div className="price">
                              <span className="icon-dolar1"></span>
                              <p>
                                $83,000 - $110,000{" "}
                                <span className="year">/year</span>
                              </p>
                            </div>
                            <p className="days">24 days left to apply</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </TabPanel>
            ))}
          </div>
        </Tabs>
      </div>
    </section >
  );
}

export default TabJob;
