import React from 'react';
import { TagPicker, Tag, CheckPicker, VStack } from 'rsuite';

export default function CompAutoSearch({ value, onChange, list }) {
    const handleSkillChange = (newValue) => {
        onChange(newValue || []); // Ensure an empty array if no value is selected
    };
    // list.map((item) => ({ label: item, value: item }))
    return (
        <div style={{ width: '100%', maxWidth: 500, marginBottom: 16 }}>
            {/* <VStack>
                <CheckPicker
                    onChange={handleSkillChange}
                    value={value} data={list} style={{ width: 224 }} />
            </VStack> */}
            <TagPicker
                data={list}
                value={value}
                onChange={handleSkillChange}
                style={{
                    width: '300px',
                    // padding: '5px',
                    // fontSize: '14px',
                    borderRadius: '8px',
                    // border: '1px solid #ccc',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                }}
            />

            {/* <TagPicker
                data={list.map((item) => ({ label: item, value: item }))} // Transform list into RSuite's data format
                placeholder="Search and add skills"
                block
                creatable
                value={value}
                onChange={handleSkillChange}
                renderExtraFooter={() => (
                    <div style={{ padding: 8 }}>
                        <span>Selected {value?.length || 0}/{list.length}</span>
                    </div>
                )}
                style={{
                    width: '100%', // Full width of the parent container
                    minWidth: 300, // Ensures a reasonable minimum width
                    maxWidth: 500, // Restricts maximum width
                }}
            />
            {value?.length > 0 && (
                <div style={{ marginTop: 8 }}>
                    {value.map((skill, index) => (
                        <Tag key={index} color="blue" style={{ marginRight: 4 }}>
                            {skill}
                        </Tag>
                    ))}
                </div>
            )} */}
        </div>
    );
}
