
import { Box, Typography, Autocomplete, TextField, Chip } from '@mui/material';
import React, { useState, useRef } from 'react';
import { SelectPicker } from 'rsuite';
export default function CompSelectSearch({ label, value, onChange, list }) {
    const styles = { width: 224, display: 'block', marginBottom: 10 };

    return (
        <Box sx={{ width: '100%', marginBottom: 2 }}>
            <SelectPicker
                size="lg"
                data={list}
                value={value}
                onChange={onChange}
                searchable={true}
                placeholder="Select search"
                style={styles}
            />
        </Box>
    );
};