import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getApp, getAppUrl } from "./utils/helpers";
import AppCandidate from "./layouts/AppCandidate";
import AppEmployer from "./layouts/AppEmployer";
import Page404 from "./pages/Page404";
const App = () => {
  /* The `try {` block in the code snippet is used to wrap around a section of code that might throw an
  error during execution. It allows you to catch and handle any potential errors that occur within
  that block. In this case, the try block is not complete as it lacks a corresponding `catch` or
  `finally` block to handle or finalize the error handling. */
  // try {
  // const appUrlResult = getAppUrl();
  // console.log("getAppUrl() Result:", appUrlResult);

  const pathname = getAppUrl(); // Array.isArray(appUrlResult) ? appUrlResult : [];
  console.log("App Type Extracted:", pathname);

  if (pathname == 2) {
    console.log("AppEmployer  App");

    return <AppEmployer />;
  } else {
    console.log("AppCandidate App");
    return <AppCandidate />;
  }
  // switch (appType) {
  //   case 1:
  //   case 2:
  //   default:
  //     return (
  //       <Router>
  //         <Routes>
  //           <Route path="*" element={<Page404 />} />
  //         </Routes>
  //       </Router>
  //     );
  // }
  // } catch (error) {
  //   console.error("Error in app routing:", error.message);
  //   return <div>Error loading application. Please try again later.</div>;
  // }
};

export default App;
