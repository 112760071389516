import React, { useEffect, useRef, useState } from "react";

import Header1 from "../../components/header/Header1";
import Footer from "../../components/footer";
import Gotop from "../../components/gotop";
import axios from 'axios';
import domain from "../../api/constent";
import CardComp from "../../components/card-style/cardComp";

import { Link, useNavigate } from "react-router-dom";
import RoleComp from "../../components/Role/RoleComp";
import EducationComp from "../../components/Education/EducationComp";
import LicensesAComp from "../../components/LicencesAndCertificate/LicensesAComp";
import SkillComp from "../../components/Skill/SkillComp";
import LanguageComp from "../../components/language/LanguageComp";

import Session from "../../api/session";

import CardMedia from '@mui/material/CardMedia';
import EditIcon from '@mui/icons-material/Edit';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container } from "@mui/material";

import apiService from "../../api/apiService";

export default function ReviewSubmit() {

    const [countShow, setCountShow] = useState({ careerCount: 0, educationCount: 0, licenseCount: 0, skillCount: 0, languageCount: 0 });
    //  coverLetter, role, education, licenses, skill, language

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const careerDetails = await apiService.getDetailsRoleList();
                const educationDetails = await apiService.getDetailsEducationList();
                const licenseDetails = await apiService.getDetailsLicensesList();
                const skillDetails = await apiService.getDetailsSkillList();
                // const languageDetails = await apiService.getDetailsLanguageList();
                // Update the state with both counts
                setCountShow((prevState) => ({
                    ...prevState, // Preserve existing state
                    careerCount: careerDetails.length,
                    educationCount: educationDetails.length,
                    licenseCount: licenseDetails.length,
                    skillCount: skillDetails.length,
                    languageCount: 2//languageDetails.length
                }));
            } catch (error) {
                console.error("Error fetching counts:", error);
            }
        };

        fetchCounts();
    })

    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );

    return (
        <>
            <strong>Review and Submit</strong>
            <p>Please review your information and submit:</p>


            <div className="card" >

                <CardComp />
                <div className="container pl-4">
                    <br />
                    <h5>Profile Summary</h5>
                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                        When you apply for a job, your Profile and any verified credentials will be accessed by Pirotta services commercial as part of your job application.</Typography>
                    <strong>Documents included</strong>
                    <Card  >
                        <CardContent >
                            <Typography sx={{ fontSize: 14 }} gutterBottom>
                                <h6> Resume</h6>

                            </Typography>
                            <Container sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ ml: 'auto' }}>
                                    <EditIcon />
                                </Box>
                                <br />
                            </Container>
                            <Typography variant="h6" >
                                <p>{0} resume</p>
                            </Typography>
                        </CardContent>
                    </Card>
                    <br />  <Card  >
                        <CardContent >
                            <Typography sx={{ fontSize: 14 }} gutterBottom>
                                <h6>Cover letter</h6>

                            </Typography>
                            <Container sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ ml: 'auto' }}>
                                    <EditIcon />
                                </Box>
                                <br />
                            </Container>
                            <Typography variant="h6" >
                                <p>{0} cover letter</p>
                            </Typography>
                        </CardContent>
                    </Card>
                    <br />
                    <Card  >
                        <CardContent >
                            <Typography sx={{ fontSize: 14 }} gutterBottom>
                                <h6>Career history</h6>

                            </Typography>
                            <Container sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ ml: 'auto' }}>
                                    <EditIcon />
                                </Box>
                                <br />
                            </Container>
                            <Typography variant="h6" >
                                <p>{countShow.careerCount} roles</p>
                            </Typography>
                        </CardContent>
                    </Card>
                    <br />
                    <Card >
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} gutterBottom>
                                <h6>Education</h6>
                            </Typography>
                            <Container sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ ml: 'auto' }}>
                                    <EditIcon />
                                </Box>
                                <br />
                            </Container>
                            <Typography variant="h6" >
                                <p>{countShow.educationCount} qualification</p>
                            </Typography>
                        </CardContent>
                    </Card>
                    <br />
                    <Card >
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} gutterBottom>
                                <h6>Licences & Certifications</h6>
                            </Typography>
                            <Container sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ ml: 'auto' }}>
                                    <EditIcon />
                                </Box>
                                <br />
                            </Container>
                            <Typography variant="h6" >
                                <p>{countShow.licenseCount} credential</p>
                            </Typography>
                        </CardContent>
                    </Card>
                    <br />
                    <Card >
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} gutterBottom>
                                <h6>Skills</h6>
                            </Typography>
                            <Container sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ ml: 'auto' }}>
                                    <EditIcon />
                                </Box>
                                <br />
                            </Container>
                            <Typography variant="h6" >
                                <p>{countShow.skillCount} skills</p>
                            </Typography>
                        </CardContent>
                    </Card>
                    <br />
                    <Card >
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} gutterBottom>
                                <h6>Languages</h6>
                            </Typography>
                            <Container sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ ml: 'auto' }}>
                                    <EditIcon />
                                </Box>
                                <br />
                            </Container>
                            <Typography variant="h6" >
                                <p>{countShow.languageCount} languages</p>
                            </Typography>
                        </CardContent>
                    </Card>


                </div>
            </div>

        </>
    );
}
