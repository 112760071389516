import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

import { Typography } from '@mui/material';

const JODITEditor = ({ label, editor, content, placeholder, onBlurs, onChanges }) => {
    const config = {
        readonly: false, // Editor is editable
        height: 400, // Set height of the editor
        placeholder: placeholder || "Enter Text Here..", // Placeholder text
        toolbar: {
            buttons: [
                "bold", "italic", "underline", "strikethrough", "font", "fontsize",
                "paragraph", "align", "orderedlist", "unorderedlist", "outdent", "indent",
                "link", "image", "video", "table", "clean", "undo", "redo", "copy", "paste"
            ]
        },
        speechRecognition: false,
        toolbarAdaptive: false, 
        toolbarSticky: false,
    };

    return (
        <>
            <Typography
                variant="subtitle1"
                sx={{
                    fontWeight: "bold",
                }}
                gutterBottom
            >
                {label} <span style={{ color: "red" }}>*</span>
            </Typography>
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1}
                onBlur={newContent => onBlurs(newContent)}
                onChange={newContent => {
                    // console.log(newContent);

                }}
            />

            {/* <div style={{ marginTop: "20px" }}>
                <h4>Job Description Preview:</h4>
                <div
                    style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        minHeight: "100px",
                        background: "#f9f9f9",
                    }}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div> */}
        </>
    );
};


export default JODITEditor;
