import React from "react";
import { Grid, Box } from "@mui/material";

import { ComboBoxAutocomplete } from "../../../Employer/components/Forms/AutoComplete/ComboBoxAutocomplete";

import { MultipleValuesAutocomplete } from "../../../Employer/components/Forms/AutoComplete/MultipleValuesAutocomplete";
import { CheckboxesAutocomplete } from "../../../Employer/components/Forms/AutoComplete/CheckboxesAutocomplete";
import { SizesAutocomplete } from "../../../Employer/components/Forms/AutoComplete/SizesAutocomplete";
import PostJobFormVacancy from '../../components/PostJobFormVancany';

const PostJobVacancy = () => {
    // 2


    return (
        <Box>
            <p>POST a Job-Hot Vacancy</p>
            <Grid item xs={12} >
                <PostJobFormVacancy />
            </Grid>
            {/* <Grid container spacing={0}>
                <Grid
                    item
                    xs={12}
                    lg={4}
                    sm={6}
                    sx={{
                        display: "flex",
                        alignItems: "stretch",
                    }}
                >
                    <ComboBoxAutocomplete />
                </Grid>

                <Grid
                    item
                    xs={12}
                    lg={4}
                    sm={6}
                    sx={{
                        display: "flex",
                        alignItems: "stretch",
                    }}
                >
                    <MultipleValuesAutocomplete />
                </Grid>
    <Grid
                    item
                    xs={12}
                    lg={4}
                    sm={6}
                    sx={{
                        display: "flex",
                        alignItems: "stretch",
                    }}
                >
                    <CheckboxesAutocomplete />
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={4}
                    sm={6}
                    sx={{
                        display: "flex",
                        alignItems: "stretch",
                    }}
                >
                    <SizesAutocomplete />
                </Grid>
            </Grid> */}
        </Box>
    );
};

export default PostJobVacancy;
