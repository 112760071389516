import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
Page404.propTypes = {

};

function Page404(props) {
    return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
            <p>
                You can go back to the <Link to="/">Home Page</Link>.
            </p>
        </div>
    );
}

export default Page404;