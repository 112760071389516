import React, { useState, useEffect } from "react";
import { Button, TextField, List, ListItem, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Box, Grid, Typography, Chip } from "@mui/material";
import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";
import Session from '../../api/session';
import axios from '../../api/axios';
import domain from "../../api/constent";
import { ShimmerCategoryItems } from "shimmer-effects-react";
import apiService from "../../api/apiService";

export default function LanguageComp() {
    const [openDialog, setOpenDialog] = useState(false);
    const [skillInput, setSkillInput] = useState("");
    const [suggestedSkills, setSuggestedSkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [lists, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchSkillList();
        selectedCandidateSkillList();
    }, []);

    const handleAddSkill = async (skill) => {
        const isAlreadySelected = selectedSkills.some(selectedSkill => selectedSkill.id === skill.id);
        const isAlreadyInList = lists.some(listItem => listItem.id === skill.id);

        if (!isAlreadySelected) {
            const newSelectedSkills = [...selectedSkills, skill];
            setSelectedSkills(newSelectedSkills);

            try {
                const sessionData = Session.getDataSessionStore();
                await axios.post(`${domain.Candidate_Languages_Edit}${skill.id}`, {
                    registration_id: sessionData.id,
                    add_languages: newSelectedSkills.map(skill => skill.id),
                });
                console.log("Skill added successfully:", skill);

                // Add skill to lists if not already there
                if (!isAlreadyInList) {
                    const updatedList = [...lists, skill];
                    setList(updatedList);
                }
            } catch (error) {
                console.error("Error adding skill:", error);
            }
        }
    };

    const handleRemoveSkill = async (skill) => {
        const updatedSelectedSkills = selectedSkills.filter((item) => item.id !== skill.id);
        setSelectedSkills(updatedSelectedSkills);
        try {
            const sessionData = Session.getDataSessionStore();
            await axios.delete(`${domain.Candidate_Languages_delete}${sessionData.id}/${skill.id}`);
            console.log("Skill removed successfully:", skill);
            // Remove skill from the list without duplicates
            const updatedList = lists.filter(item => item.id !== skill.id);
            setList(updatedList);
        } catch (error) {
            console.error("Error removing skill:", error);
        }
    };

    const fetchSkillList = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await apiService.getLanguagesList();
            // console.log(data);

            setSuggestedSkills(data);
        } catch (err) {
            setError("Failed to fetch data. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const selectedCandidateSkillList = async () => {
        const sessionData = Session.getDataSessionStore();
        if (sessionData && sessionData.id && sessionData.email) {
            axios.get(`${domain.Candidate_Languages_List}${sessionData.id}`)
                .then((response) => {
                    setList(response.data.data || []);
                    setSelectedSkills(response.data.data);
                    setLoading(false);

                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setLoading(false);
                });
        } else {
            console.error("Invalid session data");
            setLoading(false);
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        selectedCandidateSkillList();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSaveSkills = async () => {
        const sessionData = Session.getDataSessionStore();
        if (!sessionData || !sessionData.id) {
            console.error("Invalid session data");
            return;
        }

        const skillData = {
            registration_id: sessionData.id,
            add_languages: selectedSkills.map(skill => skill.id),
        };

        try {
            await axios.post(`${domain.Candidate_Languages_Edit}${sessionData.id}`, skillData);
            console.log("Skills saved successfully:", skillData);
            setOpenDialog(false);
            // Merge the newly added skills with the existing list
            const updatedList = [...new Set([...lists, ...selectedSkills])];
            setList(updatedList);
            setSelectedSkills([]);
        } catch (error) {
            console.error("Error saving skills:", error);
        }
    };

    return (
        <div>
            <div className="pt-2">
                <Typography variant="h6" component="strong" gutterBottom>
                    Languages
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                    Let employers know how valuable you can be to them.
                </Typography>

                <div>
                    {
                        loading ? (
                            <ShimmerCategoryItems mode="light" />
                        ) : (
                            // Check if lists is defined and is an array before calling map
                            Array.isArray(lists) && lists.length > 0 ? (
                                lists.map((item) => (
                                    <Box key={item.id} sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                                        <Chip
                                            key={item.id}
                                            label={item.language}
                                            color="secondary"
                                            sx={{
                                                margin: "5px",
                                                "& .MuiChip-deleteIcon": {
                                                    color: "#fff",
                                                },
                                            }}
                                        />
                                    </Box>
                                ))
                            ) : (
                                <div>No languages available</div> // Or some fallback content
                            )
                        )
                    }
                </div>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenDialog}
                    startIcon={<AddIcon />}
                >
                    Add Languages
                </Button>
            </div>

            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" component="strong">
                            Add Languages
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleCloseDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                label="Search Skills"
                                variant="outlined"
                                fullWidth
                                value={skillInput}
                                onChange={(e) => setSkillInput(e.target.value)}
                                placeholder="Type skill name"
                                size="small"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: 2,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="strong" gutterBottom>
                                Suggested Languages List
                            </Typography>
                            <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                                <List>
                                    {suggestedSkills
                                        .filter((skill) => skill.language.toLowerCase().includes(skillInput.toLowerCase())) // Filter based on input
                                        .map((skill) => (
                                            <ListItem
                                                key={skill.id}
                                                button
                                                onClick={() => handleAddSkill(skill)} // Handle click event
                                                sx={{
                                                    "&:hover": {
                                                        backgroundColor: "#f1f1f1",
                                                    },
                                                }}
                                            >
                                                <ListItemText primary={skill.language} /> {/* Display the skill name */}
                                            </ListItem>
                                        ))}
                                </List>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body1" component="strong" gutterBottom>
                                Added Languages
                            </Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                                {selectedSkills && selectedSkills.length > 0 ? (
                                    selectedSkills.map((skill) => (
                                        <Chip
                                            key={skill.id}
                                            label={skill.language}
                                            onDelete={() => handleRemoveSkill(skill)}
                                            color="primary"
                                            sx={{
                                                margin: "5px",
                                                "& .MuiChip-deleteIcon": {
                                                    color: "#fff",
                                                },
                                            }}
                                        />
                                    ))
                                ) : (
                                    <div>No skills selected</div>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions sx={{ paddingRight: 3 }}>
                    <Button onClick={handleCloseDialog} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveSkills}
                        variant="contained"
                        color="primary"
                        sx={{
                            textTransform: "none",
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}