import React, { useState } from 'react';
import { TagPicker, Form } from 'rsuite';

const
    CustomTagPicker = React.forwardRef((props, ref) => {
        const {
            name,
            label,
            required = false,
            placeholder,
            value,
            apiList,
            onHandlerChange,
            error,
            ...rest
        } = props;

        return (
            <Form.Group ref={ref}>
                <Form.ControlLabel>
                    {label} {required && <span style={{ color: 'red' }}>*</span>}
                </Form.ControlLabel>
                <TagPicker
                    name={name}
                    value={value}
                    data={apiList}
                    onChange={onHandlerChange}
                    placeholder={placeholder}
                    style={{ width: 300, color: 'blue' }}
                    {...rest} />

                {error && (
                    <div style={{ color: 'red', marginTop: '4px', fontSize: '12px' }}>
                        {error}
                    </div>
                )}
            </Form.Group>
        );
    });

export default
    CustomTagPicker;
