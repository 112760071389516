
import domain from "./constent";
import Session from "./session";

import axios from './axios';
import DosAxios from '../api/axios';
// import Session from "../api/session";
import { sweetAlert } from "../utils/sweetAlert";

// Profile Details 
const fetchPersonalDetails = async () => {
  try {
    const response = await axios.post(domain.Personal_Details);
    if (response.data && response.data.data) {
      return response.data.data;
    }
    throw new Error("No data found");
  } catch (error) {
    console.error('Error fetching personal details:', error);
    throw error;
  }

};
const updatePersonalDetails = async (getJson) => {
  try {
    const userId = Session.getDataSessionStore().id;
    const resp = await axios.post(`${domain.Update_Personal_Details}/${userId}`, getJson);
    if (resp.data && resp.data) {
      return resp.data;
    }
    throw new Error("No data found");
  } catch (error) {
    console.error('Error fetching personal details:', error);
    throw error;
  }
};

// Profile Role
const getDetailsRoleList = async () => {
  const userId = Session.getDataSessionStore().id;
  try {
    const response = await axios.get(
      `${domain.Career_History_List}${userId}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const getDetailsRoleCreate = async (formData) => {

  try {
    const response = await axios.post(domain.Career_History_Create, formData);
    // getDetailsRoleList();
    console.log(response);

    sweetAlert("Success!", "You Role has been created successfully.", 1); // Success
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    sweetAlert("error!", error, 2); // Success

    throw error;
  }
};

const getDetailsRoleUpdate = async (getFindID, formData) => {
  try {
    const response = await axios.post(`${domain.Career_History_Edit}${getFindID}`, formData);
    console.log(getFindID, formData);
    sweetAlert("Success!", "You Role has been Update successfully.", 1); // Success
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    sweetAlert("error!", error, 2); // Success

    throw error;
  }
}

const getDetailsEducationList = async () => {
  try {
    const userId = Session.getDataSessionStore().id;
    const response = await axios.get(`${domain.Education_List}${userId}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const getDetailsLicensesList = async () => {
  try {
    const userId = Session.getDataSessionStore().id;
    const response = await axios.get(`${domain.Licenses_List}${userId}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const getDetailsSkillList = async () => {
  try {
    const userId = Session.getDataSessionStore().id;
    const response = await axios.get(`${domain.Candidate_Skills_List}${userId}`);

    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

const getSkillList = async () => {
  // try {
  //   const response = await axios.get(`${domain.all_Skill_list}`);
  //   return response.data.data;
  // } catch (error) {
  //   console.error("Error fetching data:", error);
  //   throw error;
  // }
}

const getLanguagesList = async () => {
  try {
    const response = await axios.get(`${domain.all_Languages_List}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
// Employer Side 
// Questions list on Running
const getQuestionsList = async () => {
  try {
    // const userId = Session.getDataSessionStore().id;
    // ${userId}
    const response = await DosAxios.get(`${domain.Employer_QuestionsList}`);
    console.log(response.data.data);
    // sweetAlert("Error!", "No token found. Please log in again.", 2);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const getAddQuestions = async (DataCollect) => {
  try {
    // const userId = Session.getDataSessionStore().id;
    const response = await DosAxios.post(`${domain.Employer_QuestionsStore}`, DataCollect);
    console.log(response.data.data);
    // sweetAlert("Error!", "No token found. Please log in again.", 2);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
async function callLogout() {
  try {
    // Include the Authorization token in the headers
    const token = localStorage.getItem("token");
    console.log(token);

    if (!token) {
      sweetAlert("Error!", "No token found. Please log in again.", 2);
      throw new Error("No token found");
    }
    await Session.getCSRF();
    const res = await DosAxios.post(`${domain.Logout_URL}`);

    // Success response
    sweetAlert("Success!", res.data.message, 1);
    sessionStorage.clear();
    localStorage.clear();
    // Clear cookies manually after logout

    document.cookie = "XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

    // Delete laravel_session cookie
    document.cookie = "laravel_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    window.location.replace("/");

    console.log("Response:", res);
    return res;
  } catch (err) {
    console.error("Error occurred:", err);

    // Check if error is due to token expiry
    if (err.response && err.response.status === 401) {
      sweetAlert("Error!", "Session expired. Please log in again.", 2);
    } else {
      sweetAlert("Error!", "An unexpected error occurred.", 2);
    }
    throw err;

  }

}


const GetEmploymentType = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Employment_Type_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

const GetKeySkillsList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Skills_List}`);

    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}


const GetDepartmentList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Department_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const GetRoleList = async (DepartmentID) => {
  try {
    console.log(`Depart ID: ${DepartmentID}`);
    const response = await DosAxios.get(`${domain.Get_Role_List}${DepartmentID}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const GetWorkModeList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Working_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const GetJobLocationList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Job_Desc_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const GetWorkExpList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Work_Exp_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const GetSalaryTypeList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Salary_Type_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const GetSalaryProcessTypeList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Salary_Process_Type_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const GetSalaryRangeList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Salary_Range_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

const GetCompanyIndustryList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Company_Industry_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

const GetEducationQualificationList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Education_Qualification_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const GetCandidateIndustryList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Candidate_Industry_List}`);
    console.log(response.data.data);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const GetNotificationList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Notification_Type_List}`);
    console.log(response.data.data);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const GetCompanySizeList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Company_Size_List}`);
    console.log(response.data.data);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
const GetEmailSubUserList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Company_Sub_User_List}`);
    console.log(response.data.data);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

const apiService = {
  callLogout,
  fetchPersonalDetails,
  updatePersonalDetails,
  getDetailsRoleList,
  getDetailsRoleCreate,
  getDetailsRoleUpdate,
  getDetailsEducationList,
  getDetailsLicensesList,
  getDetailsSkillList,
  getSkillList,
  getLanguagesList,
  getQuestionsList,
  getAddQuestions,
  GetEmploymentType,
  GetKeySkillsList,
  GetDepartmentList,
  GetRoleList,
  GetWorkModeList,
  GetJobLocationList,
  GetWorkExpList,
  GetSalaryTypeList,
  GetSalaryRangeList,
  GetSalaryProcessTypeList,
  GetCompanyIndustryList,
  GetEducationQualificationList,
  GetCandidateIndustryList,
  GetNotificationList,
  GetCompanySizeList,
  GetEmailSubUserList
};

export default apiService;
