import React, { useState, useEffect } from "react";
import axiosDom from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import img from "../../assets/images/review/google.png";
import img2 from "../../assets/images/review/tweet.png";
import domain from "../../api/constent";
import { sweetAlert } from '../../utils/sweetAlert';
import rootSession from "../../api/session";
import axiosApi from "../../api/axios";
import axiosOrigin from 'axios';
import EmployerRouter from '../../routers/routers';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

// export const getCSRFToken = () => axiosApi.get("/sanctum/csrf-cookie");

function SignIn() {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({
    usernameOrEmail: "",
    password: ""
  });
  const [formData2, setFormData2] = useState({
    usernameOrEmail: "",
    password: ""
  });

  const [activeTab, setActiveTab] = useState(0); // Set initial tab index to 0 (Candidate)


  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [err, setError] = useState(null);
  const [err2, setError2] = useState(null);

  const [token, setToken] = useState('');

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    rootSession.getEmployerChecking();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormData2((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await rootSession.getCSRF();
      const response = await axiosApi.post(domain.Login_URL, {
        email: formData.usernameOrEmail,
        password: formData.password,
        remember_me: false,
        type: 1,
      });
      console.log(response.data.accessToken);
      const accessToken = response.data.accessToken;

      localStorage.setItem("token", response.data.accessToken);

      if (accessToken) {
        await rootSession.getCSRF();

        const loginDetails = await axiosApi.post(domain.LoggedIn, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        const userData = {
          id: loginDetails.data.data.id,
          email: loginDetails.data.data.email,
          type: 1,
          profileImage: loginDetails.data.data.profile_img,
          name: loginDetails.data.data.name,
          firstName: loginDetails.data.data.first_name,
          lastName: loginDetails.data.data.last_name,
          status: loginDetails.data.data.status,
          token: `Bearer ${localStorage.getItem("token")}`
        };
        sweetAlert('Success!', 'You have logged in successfully.', 1); // Succes
        sessionStorage.setItem('user', JSON.stringify(userData));
        navigate("/");
      } else {
        sweetAlert('Error!', 'Token not found.', 2); // Error

      }
    } catch (err) {
      // Check for validation errors and handle
      if (err.response && err.response.status === 422) {
        sweetAlert('Error!', err.response.data.errors.email ? err.response.data.errors.email[0] : 'Invalid credentials.', 2); // Error
        setErrors(err.response.data.errors); // Set validation errors from the server
      } else {
        sweetAlert('Error!', 'Invalid credentials provided.', 2); // Error

        setError(err.response ? err.response.data.message : "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit2 = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError2(null);

    try {
      await rootSession.getCSRF();

      const response = await axiosApi.post(domain.EmployerLogin_URL, {
        email: formData2.usernameOrEmail,
        password: formData2.password,
        remeber_me: true,
        type: 2,
      });
      console.log(response.data.accessToken);
      const accessToken = response.data.accessToken;
      localStorage.setItem("token", response.data.accessToken);

      if (accessToken) {
        await rootSession.getCSRF();

        const loginDetails = await axiosApi.post(domain.LoggedIn, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        const userDataChecked = {
          id: loginDetails.data.data.id,
          name: loginDetails.data.data.name || 'N/A',
          encryptId: loginDetails.data.data.encrypt_id || 'N/A',
          firstName: loginDetails.data.data.first_name || 'N/A',
          lastName: loginDetails.data.data.last_name || 'N/A',
          phoneNo: loginDetails.data.data.phone_no || 'N/A',
          email: loginDetails.data.data.email || 'N/A',
          countryCode: loginDetails.data.data.country_code || 'N/A',
          homeLocation: loginDetails.data.data.home_location || 'N/A',
          businessLocation: loginDetails.data.data.business_location || 'N/A',
          profileImage: loginDetails.data.data.profile_image || '',
          businessLogo: loginDetails.data.data.business_logo || '',
          status: loginDetails.data.data.status || 0,
          type: 2, // Assuming the user type is static for now
          token: `Bearer ${localStorage.getItem("token")}` || null,
        };


        sessionStorage.setItem('employer', JSON.stringify(userDataChecked));
        window.location.replace("/employer#/dashboard");

        // window.relocate.replace = "/employer#/dashboard";
        // navigate("/employer#/dashboard");
        sweetAlert('Success!', 'You have logged in successfully.', 1); // Succes
      } else {
        sweetAlert('Error!', 'Token not found.', 2); // Error

      }
    } catch (err) {
      // Check for validation errors and handle
      if (err.response && err.response.status === 422) {
        sweetAlert('Error!', err.response.data.errors.email ? err.response.data.errors.email[0] : 'Invalid credentials.', 2); // Error
        setErrors2(err.response.data.errors); // Set validation errors from the server
      } else {
        sweetAlert('Error!', 'Invalid credentials provided.', 2); // Error

        setErrors2(err.response ? err.response.data.message : "An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
    // Set roleType based on selected tab
    if (index === 0) {
      setFormData({ ...formData, roleType: 1 }); // Candidate
    } else if (index === 1) {
      setFormData2({ ...formData2, roleType: 2 }); // Employer
    }
  };

  // D
  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <Tabs
            className="wd-form-login tf-tab"
            selectedIndex={activeTab}
            onSelect={handleTabChange}
          >
            <h4>Log In</h4>
            <TabList className="menu-tab">
              <Tab className="ct-tab">Candidate</Tab>
              <Tab className="ct-tab">Employer</Tab>
            </TabList>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">

                <div className="wd-form-login">
                  <form onSubmit={handleSubmit}>
                    <div className="ip">
                      <label>
                        Username or email address<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="usernameOrEmail"
                        onChange={(e) => setFormData({ ...formData, usernameOrEmail: e.target.value })}
                        placeholder="Email or Username"
                        required
                      />
                      {errors.usernameOrEmail && <p className="error">{errors.usernameOrEmail[0]}</p>}
                    </div>

                    <div className="ip">
                      <label>
                        Password<span>*</span>
                      </label>
                      <div className="inputs-group auth-pass-inputgroup">
                        <input
                          type={showPass ? "text" : "password"}
                          className="input-form password-input"
                          autocomplete="current-password"
                          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                          placeholder="Password"
                          required
                        />
                        <Link
                          className={`password-addon ${showPass ? "icon-eye" : "icon-eye-off"}`}
                          onClick={() => setShowPass(!showPass)}
                        />
                      </div>
                      {errors.password && <p className="error">{errors.password[0]}</p>}
                    </div>

                    {/* Display general error if exists */}
                    {err && <p className="error">{err}</p>}


                    <div className="group-ant-choice">
                      <div className="sub-ip">
                        <input type="checkbox" />
                        Remember me
                      </div>
                      <Link to="#" className="forgot">
                        Forgot password?
                      </Link>
                    </div>
                    <button type="submit" disabled={loading}>
                      {loading ? 'Logging in...' : 'Login'}
                    </button>

                    <div className="sign-up p-3">
                      Not registered yet? <Link to="/create-account">Sign Up</Link>
                    </div>
                    <p className="line-ip">
                      <span>or sign up with</span>
                    </p>

                    {/* Social media login options */}
                    {/* <Link to="#" className="btn-social">
                      Continue with Facebook
                    </Link> */}
                    <Link to="#" className="btn-social">
                      <img src={img} alt="Google" /> Continue with Google
                    </Link>
                    {/* <Link to="#" className="btn-social">
                      <img src={img2} alt="Twitter" /> Continue with Twitter
                    </Link> */}


                  </form>
                </div>
              </TabPanel>
              <TabPanel className="inner animation-tab">
                <form onSubmit={handleSubmit2}>
                  <div className="ip">
                    <label>
                      Username or email address<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="usernameOrEmail"
                      onChange={(e) => setFormData2({ ...formData2, usernameOrEmail: e.target.value })}
                      placeholder="Email or Username"
                      required
                    />
                    {errors2.usernameOrEmail && <p className="error">{errors2.usernameOrEmail[0]}</p>}
                  </div>

                  <div className="ip">
                    <label>
                      Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={showPass2 ? "text" : "password"}
                        className="input-form password-input"
                        name="password"
                        autocomplete="current-password"
                        onChange={(e) => setFormData2({ ...formData2, password: e.target.value })}
                        placeholder="Password"
                        required
                      />
                      <Link
                        className={`password-addon ${showPass2 ? "icon-eye" : "icon-eye-off"}`}
                        onClick={() => setShowPass2(!showPass2)}
                      />
                    </div>
                    {errors2.password && <p className="error">{errors2.password[0]}</p>}
                  </div>

                  {/* Display general error if exists */}
                  {err2 && <p className="error">{err2}</p>}


                  <div className="group-ant-choice">
                    <div className="sub-ip">
                      <input type="checkbox" />
                      Remember me
                    </div>
                    <Link to="#" className="forgot">
                      Forgot password?
                    </Link>
                  </div>
                  <button type="submit" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                  </button>

                  <div className="sign-up p-3">
                    Not registered yet? <Link to="/create-account">Sign Up</Link>
                  </div>
                  <p className="line-ip">
                    <span>or sign up with</span>
                  </p>

                  {/* Social media login options */}
                  {/* <Link to="#" className="btn-social">
                      Continue with Facebook
                    </Link> */}
                  <Link to="#" className="btn-social">
                    <img src={img} alt="Google" /> Continue with Google
                  </Link>
                  {/* <Link to="#" className="btn-social">
                      <img src={img2} alt="Twitter" /> Continue with Twitter
                    </Link> */}


                </form>
              </TabPanel>
            </div>
          </Tabs>

        </div>
      </div>
    </section>
  );
}

export default SignIn;
