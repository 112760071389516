import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import axios from "../../api/axios";
import domain from "../../api/constent";
import Swal from "sweetalert2";

function SignUp() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    roleType: 1, // default
    termsAccepted: false,
  });
  const [formData2, setFormData2] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    roleType: 2,
    termsAccepted: false,
  });

  const [errors, setErrors] = useState({});
  const [errors2, setErrors2] = useState({});

  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // Set initial tab index to 0 (Candidate)

  useEffect(() => {
    async function initializeCsrfToken() {
      await axios.get(domain.Sanctum_CSRF);
    }
    initializeCsrfToken();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    // Remove error when input changes
    if (errors[name]) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };

  const handleChange2 = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData2({
      ...formData2,
      [name]: type === "checkbox" ? checked : value,
    });

    // Remove error when input changes
    if (errors2[name]) {
      setErrors2((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
    // Set roleType based on selected tab
    if (index === 0) {
      setFormData({ ...formData, roleType: 1 }); // Candidate
    } else if (index === 1) {
      setFormData2({ ...formData2, roleType: 2 }); // Employer
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.first_name) {
      errors.first_name = "First name is required";
    }

    if (!formData.last_name) {
      errors.last_name = "Last name is required";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/^[\w.%+-]+@[\w.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      errors.email = "Enter a valid email";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }

    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    if (!formData.termsAccepted) {
      errors.termsAccepted = "You must agree to the terms";
    }

    setErrors(errors);
    return errors;
  };

  const validateForm2 = () => {
    const errors2 = {};
    if (!formData2.first_name) {
      errors2.first_name = "First name is required";
    }

    if (!formData2.last_name) {
      errors2.last_name = "Last name is required";
    }

    if (!formData2.email) {
      errors2.email = "Email is required";
    } else if (!/^[\w.%+-]+@[\w.-]+\.[A-Z]{2,}$/i.test(formData2.email)) {
      errors2.email = "Enter a valid email";
    }

    if (!formData2.password) {
      errors2.password = "Password is required";
    } else if (formData2.password.length < 8) {
      errors2.password = "Password must be at least 8 characters";
    }

    if (formData2.password !== formData2.confirmPassword) {
      errors2.confirmPassword = "Passwords do not match";
    }

    if (!formData2.termsAccepted) {
      errors2.termsAccepted = "You must agree to the terms";
    }

    setErrors2(errors2);
    return errors2;
  };

  const clearErrors = () => {
    setErrors({});
    setErrors2({});

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    const formErrors = validateForm();

    // Proceed only if no validation errors exist
    if (Object.keys(formErrors).length === 0) {
      try {
        await submitRegister(
          formData.first_name,
          formData.last_name,
          formData.email,
          formData.password,
          formData.roleType
        );

        // Reset form after successful registration
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          confirmPassword: "",
          roleType: 1, // Reset to default
          termsAccepted: false,
        });
        clearErrors();
      } catch (error) {
        console.error("Registration error:", error);
      }
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();

    // Validate form inputs
    const formErrors = validateForm2();

    // Proceed only if no validation errors exist
    if (Object.keys(formErrors).length === 0) {
      try {
        await submitRegister2(
          formData2.first_name,
          formData2.last_name,
          formData2.email,
          formData2.password,
          formData2.roleType
        );

        // Reset form after successful registration
        setFormData2({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          confirmPassword: "",
          roleType: 2, // Reset to default
          termsAccepted: false,
        });
        clearErrors();
      } catch (error) {
        console.error("Registration error:", error);
      }
    }
  };
  const submitRegister = async (first_name, last_name, email, password, roleType) => {
    try {
      const response = await axios.post(domain.CandidateRegister_URL, {
        first_name,
        last_name,
        email,
        password,
        c_password: formData.confirmPassword,
        roleType,
      });

      Swal.fire({
        icon: "success",
        title: "Registration Successful",
        text: "You have registered successfully!",
        confirmButtonText: "OK",
      });

      return response.data;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text: error.response ? error.response.data.message : error.message,
        confirmButtonText: "Try Again",
      });

      throw new Error(error.response ? error.response.data.message : error.message);
    }
  };


  const submitRegister2 = async (first_name, last_name, email, password, roleType) => {
    try {
      const response = await axios.post(domain.EmployerRegister_URL, {
        first_name,
        last_name,
        email,
        password,
        c_password: formData2.confirmPassword,
        roleType,
      });

      Swal.fire({
        icon: "success",
        title: "Registration Successful",
        text: "You have registered successfully!",
        confirmButtonText: "OK",
      });

      return response.data;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text: error.response ? error.response.data.message : error.message,
        confirmButtonText: "Try Again",
      });

      throw new Error(error.response ? error.response.data.message : error.message);
    }
  };
  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <Tabs
            className="wd-form-login tf-tab"
            selectedIndex={activeTab}
            onSelect={handleTabChange}
          >
            <h4>Create Link Free Account</h4>
            <TabList className="menu-tab">
              <Tab className="ct-tab">Candidate</Tab>
              <Tab className="ct-tab">Employer</Tab>
            </TabList>
            <div className="content-tab">
              {/* Candidate */}
              <TabPanel className="inner animation-tab">
                <form onSubmit={handleSubmit}>
                  <div className="ip">
                    <label>
                      First Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                    {errors.first_name && <p className="error">{errors.first_name}</p>}
                  </div>
                  <div className="ip">
                    <label>
                      Last Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                    {errors.last_name && <p className="error">{errors.last_name}</p>}
                  </div>
                  <div className="ip">
                    <label>
                      Email Address<span>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                  <div className="ip">
                    <label>
                      Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={showPass ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <Link
                        className={`password-addon ${showPass ? "icon-eye" : "icon-eye-off"}`}
                        onClick={() => setShowPass(!showPass)}
                      />
                    </div>
                    {errors.password && <p className="error">{errors.password}</p>}
                  </div>
                  <div className="ip">
                    <label>
                      Confirm Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={showPass2 ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                      <Link
                        className={`password-addon ${showPass2 ? "icon-eye" : "icon-eye-off"}`}
                        onClick={() => setShowPass2(!showPass2)}
                      />
                    </div>
                    {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
                  </div>
                  <div className="group-ant-choice st">
                    <div className="sub-ip">
                      <input
                        type="checkbox"
                        name="termsAccepted"
                        checked={formData.termsAccepted}
                        onChange={handleChange}
                      />
                      I agree to the <Link to="/termsofuse">Terms of Use</Link>
                    </div>
                    {errors.termsAccepted && (
                      <p className="error">{errors.termsAccepted}</p>
                    )}
                  </div>
                  <button type="submit">Register</button>
                  <div className="sign-up">
                    Already have an account?
                    <Link to="/login"> Login Here</Link>
                  </div>
                </form>
              </TabPanel>
              {/* Employer */}
              <TabPanel className="inner animation-tab">
                <form onSubmit={handleSubmit2}>
                  <div className="ip">
                    <label>
                      First Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      value={formData2.first_name}
                      onChange={handleChange2}
                    />
                    {errors2.first_name && <p className="error">{errors2.first_name}</p>}
                  </div>
                  <div className="ip">
                    <label>
                      Last Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      value={formData2.last_name}
                      onChange={handleChange2}
                    />
                    {errors2.last_name && <p className="error">{errors2.last_name}</p>}
                  </div>
                  <div className="ip">
                    <label>
                      Email Address<span>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData2.email}
                      onChange={handleChange2}
                    />
                    {errors2.email && <p className="error">{errors2.email}</p>}
                  </div>
                  <div className="ip">
                    <label>
                      Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={showPass ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        value={formData2.password}
                        onChange={handleChange2}
                      />
                      <Link
                        className={`password-addon ${showPass ? "icon-eye" : "icon-eye-off"}`}
                        onClick={() => setShowPass(!showPass)}
                      />
                    </div>
                    {errors2.password && <p className="error">{errors2.password}</p>}
                  </div>
                  <div className="ip">
                    <label>
                      Confirm Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={showPass2 ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={formData2.confirmPassword}
                        onChange={handleChange2}
                      />
                      <Link
                        className={`password-addon ${showPass2 ? "icon-eye" : "icon-eye-off"}`}
                        onClick={() => setShowPass2(!showPass2)}
                      />
                    </div>
                    {errors2.confirmPassword && <p className="error">{errors2.confirmPassword}</p>}
                  </div>
                  <div className="group-ant-choice st">
                    <div className="sub-ip">
                      <input
                        type="checkbox"
                        name="termsAccepted"
                        checked={formData2.termsAccepted}
                        onChange={handleChange2}
                      />
                      I agree to the <Link to="/termsofuse">Terms of Use</Link>
                    </div>
                    {errors2.termsAccepted && (
                      <p className="error">{errors2.termsAccepted}</p>
                    )}
                  </div>
                  <button type="submit">Register</button>
                  <div className="sign-up">
                    Already have an account?
                    <Link to="/login"> Login Here</Link>
                  </div>
                </form>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
