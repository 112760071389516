import React, { useEffect, useRef, useState } from "react";

import Header1 from "../../components/header/Header1";
import Footer from "../../components/footer";
import Gotop from "../../components/gotop";
import axios from 'axios';
import domain from "../../api/constent";
import CardComp from "../../components/card-style/cardComp";

import { Link, useNavigate } from "react-router-dom";
import RoleComp from "../../components/Role/RoleComp";
import EducationComp from "../../components/Education/EducationComp";
import LicensesAComp from "../../components/LicencesAndCertificate/LicensesAComp";
import SkillComp from "../../components/Skill/SkillComp";
import LanguageComp from "../../components/language/LanguageComp";

import Session from "../../api/session";


export default function ProfilePage({ clname = "profile" }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate loading for 3 seconds
        setTimeout(() => setLoading(false), 3000);
    }, []);


    const [jobList, setDataInFeatured] = useState([]);
    const [error, setError] = useState(null);
    const [getPassValue, setPassValue] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const WOW = require("wowjs");
        window.wow = new WOW.WOW({
            live: false,
        });
        window.wow.init();
        fetchCheckingLoginPage();
        fetchInfo();
    }, []);

    const fetchCheckingLoginPage = async () => {
        if (Session.getChecking() == true) {
            // fetchPersonalDetails();
        } else {
            // console.log("session is not exist");
            navigate("/login");
            // return false;
        }
    }
    const fetchInfo = async () => {
        try {
            setLoading(true);

            const resp = await axios.get(domain.Job_Posting_List_URL);
            console.log(resp.data);
            setDataInFeatured(resp.data);
            setLoading(false);
        } catch (err) {
            setError('Error fetching data');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>

            <div className="card" >

                <CardComp />
                <div className="container pl-4">
                    <h5>Career History</h5>
                    <br />
                    <RoleComp />
                    <br />
                    <EducationComp />
                    <br />
                    <LicensesAComp />
                    <br />
                    {/* <SkillComp /> */}
                    <br />
                    <LanguageComp />
                    <br />
                </div>
            </div>

        </>
    );
}
