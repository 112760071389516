import React, { useState } from 'react';
import Select from 'react-select';

const SearchSelect = ({ options, placeholder, frameType, showIcon, isClearable }) => {
    const [selectedOptions, setSelectedOptions] = useState(null);

    // Handle the change in selection
    const handleChange = (options) => {
        setSelectedOptions(options);
    };

    // Custom styles for fixed width
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: 250,  // Set your desired fixed width here
        }),
        menu: (provided) => ({
            ...provided,
            width: 250,
            zIndex: 9999,
        }),
    };

    if (frameType == 1) {

        return (
            <Select
                options={options}
                className="react-dropdown"
                placeholder={placeholder || "Enter Search"}
                value={selectedOptions}
                onChange={handleChange}
                isClearable={isClearable} // Allows clearing selection if true
                components={{
                    DropdownIndicator: showIcon ? undefined : () => null,
                    IndicatorSeparator: showIcon ? undefined : () => null,
                }}
                styles={customStyles}
            />
        );

    }
    if (frameType == 2) {

        return (
            <Select
                options={options}
                className="react-dropdown"
                placeholder={placeholder || "Enter Search"}
                value={selectedOptions}
                onChange={handleChange}
                isClearable={isClearable} // Allows clearing selection if true
                // components={{
                //     DropdownIndicator: showIcon ? undefined : () => null,
                //     IndicatorSeparator: showIcon ? undefined : () => null,
                // }}
                styles={customStyles}
            />
        );

    }
};

export default SearchSelect;
