import React from "react";
import { useRoutes } from "react-router-dom";
import AuthMiddleware from "../../middleware/AuthMiddleware"; // Import middleware
import routesConfigCandidate from "./routesConfigCandidate"; // Import centralized config

const AppRoutePath = () => {
    const wrappedRoutes = routesConfigCandidate.map((route) => ({
        path: route.path,
        element: route.isProtected ? (
            <AuthMiddleware>{route.element}</AuthMiddleware>
        ) : (
            route.element
        ),
    }));

    const routes = useRoutes(wrappedRoutes);

    return routes;
};

export default AppRoutePath;
