import React from 'react';
import { Form } from 'rsuite';

const CompRSTextField = React.forwardRef((props, ref) => {
    const { name, label, required, accepter, error, ...rest } = props;

    return (
        <Form.Group ref={ref}>
            <Form.ControlLabel>
                {label} {required ? <span style={{ color: 'red' }}>*</span> : null}
            </Form.ControlLabel>
            <Form.Control name={name} accepter={accepter} {...rest} />
            {error && (
                <div style={{ color: 'red', marginTop: '4px', fontSize: '12px' }}>
                    {error}
                </div>
            )}
        </Form.Group>
    );
});

export default CompRSTextField;
