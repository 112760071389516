import React, { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import FullLayout from "../../Employer/layouts/FullLayout/FullLayout.js";
import Dashboard1 from "../../Employer/views/dashboards/Dashboard1.js";
import ExAutoComplete from "../../Employer/views/FormElements/ExAutoComplete.js";
import ExButton from "../../Employer/views/FormElements/ExButton.js";
import BasicTable from "../../Employer/views/tables/BasicTable.js";
import ExCheckbox from "../../Employer/views/FormElements/ExCheckbox.js";
import ExRadio from "../../Employer/views/FormElements/ExRadio.js";
import ExSlider from "../../Employer/views/FormElements/ExSlider.js";
import ExSwitch from "../../Employer/views/FormElements/ExSwitch.js";
import FormLayouts from "../../Employer/views/FormLayouts/FormLayouts.js";
import ApplyJobVacancy from "../../Employer/views/post-vacancy/PostJobVacancy.jsx";
import PostJobPreview from "../../Employer/views/post-vacancy/PostJobPreview.jsx";

// Define the ThemeRoutes array
const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" replace /> }, // Redirect root to dashboard
      { path: "/dashboard", element: <Dashboard1 /> },
      // { path: "login", element: <Login /> },
      { path: "post-vacancy", element: <ApplyJobVacancy /> },
      { path: "post-review", element: <PostJobPreview /> },
      { path: "tables/basic-table", element: <BasicTable /> },
      { path: "form-layouts", element: <FormLayouts /> },
      {
        path: "form-elements",
        children: [
          { path: "autocomplete", element: <ExAutoComplete /> },
          { path: "button", element: <ExButton /> },
          { path: "checkbox", element: <ExCheckbox /> },
          { path: "radio", element: <ExRadio /> },
          { path: "slider", element: <ExSlider /> },
          { path: "switch", element: <ExSwitch /> },
        ],
      },
      { path: "*", element: <Navigate to="/" replace /> },
    ],
  },
  // Catch-all route for unmatched paths
];

// AppEmployerRoutePath uses ThemeRoutes with `useRoutes`
const AppEmployerRoutePath = () => {
  const routes = useRoutes(ThemeRoutes);
  return routes;
};

export default AppEmployerRoutePath;
