import React, { useState } from "react";
import { Uploader, Button, IconButton, Stack, Whisper, Tooltip } from "rsuite";
import CloseIcon from "@rsuite/icons/Close";

function ImageUploadPreviewRSuite() {
    const [images, setImages] = useState([]);

    const handleImageUpload = (fileList) => {
        const newImages = fileList.map((file) => ({
            id: file.uid, // Unique ID provided by RSuite Uploader
            src: URL.createObjectURL(file.blobFile),
        }));
        setImages((prevImages) => [...prevImages, ...newImages]);
    };

    const removeImage = (id) => {
        setImages((prevImages) => prevImages.filter((image) => image.id !== id));
    };

    return (
        <div>
            <Uploader
                action=""
                multiple
                listType="picture"
                onChange={handleImageUpload}
                draggable
                accept="image/*"
                style={{ marginBottom: "20px" }}
            >
                <Button appearance="primary">Upload Images</Button>
            </Uploader>

            <div
                style={{
                    display: "flex",
                    gap: "10px",
                    overflowX: "auto",
                    padding: "10px",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                }}
            >
                {images.length === 0 && <p>No images uploaded yet.</p>}
                {images.map((image) => (
                    <div
                        key={image.id}
                        style={{
                            position: "relative",
                            width: "120px",
                            height: "120px",
                            flex: "0 0 auto",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            overflow: "hidden",
                        }}
                    >
                        <img
                            src={image.src}
                            alt="Uploaded"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                        <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={<Tooltip>Remove Image</Tooltip>}
                        >
                            <IconButton
                                icon={<CloseIcon />}
                                size="xs"
                                circle
                                appearance="subtle"
                                style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                                }}
                                onClick={() => removeImage(image.id)}
                            />
                        </Whisper>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ImageUploadPreviewRSuite;
