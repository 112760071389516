import { React, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Switch,
  Button,
  Divider,
  IconButton,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { padding, styled } from "@mui/system";
import {
  FiSettings,
  FiRefreshCw,
  FiMaximize2,
  FiUser,
  FiDollarSign,
  FiShoppingCart,
  FiActivity,
} from "react-icons/fi";

import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {
  BlogCard,
  SalesOverview,
  ProductPerformance,
  DailyActivities,
} from "./dashboard1-components";
import { getApp, getAppUrl } from "../../../utils/helpers"; //utils/helpers";

import Session from "../../../api/session";
const DashboardWidget = styled(Paper)(({ theme }) => ({
  padding: "20px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  "&:hover": {
    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  },
}));

const StatsCard = styled(Card)({
  minHeight: "120px",
  display: "flex",
  alignItems: "center",
  padding: "16px",
});

const lineData = [
  { name: "Jan", revenue: 4000, users: 2400 },
  { name: "Feb", revenue: 3000, users: 1398 },
  { name: "Mar", revenue: 2000, users: 9800 },
  { name: "Apr", revenue: 2780, users: 3908 },
  { name: "May", revenue: 1890, users: 4800 },
];

const barData = [
  { name: "Product A", sales: 4000, returns: 400 },
  { name: "Product B", sales: 3000, returns: 300 },
  { name: "Product C", sales: 2000, returns: 200 },
];

const pieData = [
  { name: "Electronics", value: 400 },
  { name: "Fashion", value: 300 },
  { name: "Home", value: 300 },
  { name: "Books", value: 200 },
];

const recentOrders = [
  {
    id: 1,
    customer: "John Doe",
    product: "Laptop",
    amount: "$999",
    status: "Completed",
  },
  {
    id: 2,
    customer: "Jane Smith",
    product: "Smartphone",
    amount: "$699",
    status: "Pending",
  },
  {
    id: 3,
    customer: "Bob Johnson",
    product: "Tablet",
    amount: "$299",
    status: "Processing",
  },
];

const Dashboard1 = () => {
  //
  const [darkMode, setDarkMode] = useState(false);
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState("week");

  useEffect(() => {
    checkEmployer();
  }, []);

  const checkEmployer = () => {
    const isEmployerChecking = Session.getEmployerChecking(); // If async
    console.log("Employer Checking:", isEmployerChecking);

    if (isEmployerChecking === false) {
      // navigate("http://localhost:3000/login");
      window.location.replace("/login");
    }
  };
  const stats = [
    {
      title: "Total Users",
      value: "15,847",
      icon: <FiUser size={24} />,
      color: "#1976d2",
    },
    {
      title: "Revenue",
      value: "$84,327",
      icon: <FiDollarSign size={24} />,
      color: "#2e7d32",
    },
    {
      title: "Orders",
      value: "1,438",
      icon: <FiShoppingCart size={24} />,
      color: "#ed6c02",
    },
    {
      title: "Growth",
      value: "+27.4%",
      icon: <FiActivity size={24} />,
      color: "#9c27b0",
    },
  ];

  const handleRefresh = async () => {
    try {
      setError(null);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (err) {
      setError("Failed to refresh dashboard data");
    }
  };
  const optionStyle = {
    grid: {
      show: true,
      borderColor: "transparent",
      strokeDashArray: 2,
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "42%",
        endingShape: "rounded",
        borderRadius: 5,
      },
    },

    colors: ["#1e4db7", "#a7e3f4"],
    fill: {
      type: "solid",
      opacity: 1,
    },
    chart: {
      offsetX: -15,
      toolbar: {
        show: false,
      },
      foreColor: "#adb0bb",
      fontFamily: "'DM Sans',sans-serif",
      sparkline: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "category",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
        },
      },
    },
    yaxis: {
      show: true,
      min: 100,
      max: 400,
      tickAmount: 3,
      labels: {
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
        },
      },
    },
    stroke: {
      show: true,
      width: 5,
      lineCap: "butt",
      colors: ["transparent"],
    },
    tooltip: {
      theme: "dark",
    },
  };
  return (
    <Box>
      <Grid container spacing={3}>
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StatsCard>
              <CardContent sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    borderBottom: "2px solid #e0e0e0",
                  }}
                >
                  <Box>
                    <Typography color="textSecondary" gutterBottom>
                      {stat.title}
                    </Typography>
                    <Typography variant="h4" component="div">
                      {stat.value}
                    </Typography>
                  </Box>
                  <Avatar sx={{ bgcolor: stat.color }}>{stat.icon}</Avatar>
                </Box>
              </CardContent>
            </StatsCard>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{ padding: "5px" }} md={6}>
          <Card
            variant="outlined"
            sx={{
              paddingBottom: "0",
            }}
          >
            {" "}
            <DashboardWidget>
              <Typography variant="h6" gutterBottom>
                Revenue & Users Overview
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={lineData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
                  <Line type="monotone" dataKey="users" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </DashboardWidget>
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ padding: "5px" }} md={6}>
          <Card
            variant="outlined"
            sx={{
              paddingBottom: "0",
            }}
          >
            {" "}
            <DashboardWidget>
              <Typography variant="h6" gutterBottom>
                Sales Distribution
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#222", // Custom background color
                      border: "none",
                    }}
                    itemStyle={{
                      color: "#fff", // Custom text color
                    }}
                  />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </DashboardWidget>
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ padding: "5px" }} md={6}>
          <Card
            variant="outlined"
            sx={{
              paddingBottom: "0",
            }}
          >
            <DashboardWidget>
              <Typography variant="h6" gutterBottom>
                Sales Distribution
              </Typography>

              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={barData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="sales" fill="#8884d8" />
                  <Bar dataKey="returns" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </DashboardWidget>
          </Card>
        </Grid>

        {/* ------------------------- row 1 ------------------------- */}
        <Grid item xs={12} lg={12}>
          <SalesOverview />
        </Grid>
        {/* ------------------------- row 2 ------------------------- */}
        <Grid item xs={12} lg={4}>
          <DailyActivities />
        </Grid>
        <Grid item xs={12} lg={8}>
          <ProductPerformance />
        </Grid>
        {/* ------------------------- row 3 ------------------------- */}
        <BlogCard />
      </Grid>
    </Box>
  );
};

export default Dashboard1;
